<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
    buttonText: { type: String, required: false },
    headerType: { type: String, required: false },
  });
</script>
<template>
  <section class="section">
    <header class="section__header">
      <SfHeading v-if="title" large :type="headerType">
        {{ title }}
      </SfHeading>
      <SfButton v-if="link" transparent :to="link" :small="!breakpoints.md">
        <template #default>
          {{ buttonText || $t('Przejdź') }}
        </template>
        <template #after>
          <IconChevronRight />
        </template>
      </SfButton>
    </header>
    <div class="section__content">
      <slot />
    </div>
  </section>
</template>
<style scoped lang="scss">
  .section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 2rem;
    background-color: var(--color-white);
    border-radius: var(--border-radius);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  @media(max-width: 992px) {
    .section {
      padding: 1rem 0;
      gap: 1rem;
      border-radius: 0;

      &__header {
        padding: 0 0.625rem;
      }
    }
  }
</style>
