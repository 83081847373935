<script lang="ts" setup>
  import { useSession, route } from '@plenny/visitor';

  const session = useSession();
</script>
<template>
  <div class="header-favorite">
    <VisitorLink :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="header-favorite__link" :aria-label="$t('Ulubione')">
      <IconHeart />
      <span class="header-favorite__text">{{ $t('Ulubione') }}</span>
    </VisitorLink>
  </div>
</template>
<style lang="scss" scoped>
  .header-favorite {
    height: 100%;

    &.scroll {
      .header-favorite__link {
        column-gap: 0;

        .header-favorite__text {
          font-size: 0;
          opacity: 0;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.625rem;
      font-size: 1.5rem;
      height: 100%;
    }

    &__text {
      font-size: 1rem;
      transition: var(--primary-transition);

      @media(max-width: 1200px) {
        display: none;
      }
    }
  }
</style>
