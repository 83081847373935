<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import type { Brand, ProductCategory } from '$types';

  const variant = defineModel('variant', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
    brand: { type: Object as PropType<Brand>, required: true },
    page: Object,
    category: { type: Object as PropType<ProductCategory>, required: false },
  });

  const photos = computed(() => {
    return [
      ...variant.value.photos,
      ...props.product.photos,
      ...(props.product.gallery?.photos || []),
    ];
  });
</script>
<template>
  <div class="container product-details">
    <div class="product-details__gallery">
      <SfProductGallery v-bind="{ photos }" />
    </div>
    <div class="product-details__content">
      <header class="product-details__header">
        <ShopProductViewTags :product :variant icon />
        <h1 class="product-details__heading" v-html="variant.title" />
        <SfStars v-if="product.reviewsCount > 0" :count="product.reviewsCount" :rating="product.reviewsAverage" />
      </header>

      <div class="product-details__container">
        <div class="product-details__left">
          <div class="product-details__description" v-if="product.description_short" v-html="product.description_short" />
          <ShopProductViewMeta :brand :category :product :variant />
          <ShopProductViewActions :variant />
        </div>
        <ShopProductViewPicker class="product-details__right" :product v-model:variant="variant" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-product-gallery-view-details-button-width: 3rem;
    --sf-product-gallery-view-details-button-height: 3rem;
    --sf-product-gallery-view-details-button-space: 0;
    --sf-product-gallery-view-details-button-icon-size: 1.5rem;
  }

  .product-gallery .product-gallery__images {
    --sf-carousel-button-width: var(--sf-product-gallery-view-details-button-width);
    --sf-carousel-button-height: var(--sf-product-gallery-view-details-button-height);
    --sf-carousel-button-space: var(--sf-product-gallery-view-details-button-space);
    --sf-carousel-button-icon-size: var(--sf-product-gallery-view-details-button-icon-size);
  }
</style>
<style scoped lang="scss">
  .product-details {
    display: flex;
    gap: 2rem;
    margin-bottom: var(--margin-default);
    padding: var(--padding-card);
    background-color: var(--background-card);
    border-radius: var(--border-radius);

    &__description {
      font-size: 0.875rem;
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      //flex-grow: 1;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1 1 50%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 2rem
    }

    &__gallery {
      height: fit-content;
      width: 40%;
    }

    &__content {
      width: 60%;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: var(--font-weight-bold);
    }

    @media (max-width: 1200px) {
      &__container {
        grid-template-columns: 1fr
      }

      &__gallery {
        width: 45%;
      }

      &__content {
        width: 55%;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      padding-bottom: 1rem;

      &__gallery {
        width: 100%;
      }

      &__content {
        width: 100%;
        gap: 1rem;
      }

      &__container {
        gap: 1rem;
      }

      &__heading {
        font-size: 1.25rem;
      }

      &__description {
        font-size: 0.75rem;
      }
    }
  }
</style>
