<script setup lang="ts">
  import { computed } from 'vue';
  import { transChoice, trans } from '@plenny/translator';
  import { Features } from '$features';
  import { Unit } from '$types';
  import { UnitFormatter } from '$support';

  const props = defineProps({
    variant: { type: Object, required: true },
  });

  const enabled = computed(() => {
    return Features.loyaltyPoints.isEnabled() && props.variant.points > 0;
  });

  const unit = computed(() => {
    // Temporary variables to be replaced once units introduced to variants.
    let type = Unit.PIECE;
    let value = 1;

    return UnitFormatter.genitive(type, value);
  });

  const points = computed(() => {
    return transChoice(':points&nbsp;punkt|:points&nbsp;punkty|:points&nbsp;punktów', props.variant.points, { points: props.variant.points });
  });

  const message = computed(() => {
    return trans('Za zakup <b>:unit</b> tego produktu otrzymasz <b>:points</b>.', { unit: unit.value, points: points.value });
  });
</script>
<template>
  <div v-if="enabled" class="product-points" aria-live="polite">
    <SfIconCoin class="product-points__icon" />
    <div class="product-points__obtainable" v-html="message" />
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-points-gap: 0.5rem;
    --sf-points-icon-size: 0.875rem;
    --sf-points-font-size: 0.75rem;

    @include media.query(md) {
      --sf-points-icon-size: 0.875rem;
      --sf-points-font-size: 0.625rem;
    }
  }

  .product-points {
    display: flex;
    align-items: center;
    gap: var(--sf-points-gap);

    &__icon {
      flex: 0 0 var(--sf-points-icon-size);
      font-size: var(--sf-points-icon-size);
    }

    &__obtainable {
      font-size: var(--sf-points-font-size);
      line-height: var(--line-height);
    }
  }
</style>
