<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    id: { type: String, required: false },
    name: { type: String, required: false },
    label: { type: String, required: false },
    error: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    floating: { type: Boolean, required: false, default: false },
  });

  const classes = computed(() => {
    return {
      'control__wrapper--required': props.required,
      'control__wrapper--error': !!props.error,
      'control__wrapper--floating': props.floating && props.label,
    };
  });
</script>

<template>
  <div :class="classes" class="control__wrapper">
    <label v-if="!floating && label" :for="id || name" class="control__label" v-html="label" />
    <div class="control__content">
      <slot />
      <label v-if="floating && label" :for="id || name" class="control__label" v-html="label" />
    </div>
    <p v-if="error" class="control__error" v-html="error" />
  </div>
</template>

<style lang="scss">
  :root {
    --sf-input-color: rgb(0 0 0);
    --sf-input-color-disabled: var(--disabled-foreground);
    --sf-input-font-family: var(--font-family-sans);
    --sf-input-font-size: 0.875rem;
    --sf-input-font-weight: var(--font-weight-regular);
    --sf-input-line-height: 1.25rem;

    --sf-input-floating-padding-top: 1.1875rem;
    --sf-input-floating-padding-bottom: 0.3125rem;
    --sf-input-floating-label-font-size: 1rem;

    --sf-input-padding-top: 0.625rem;
    --sf-input-padding-right: 1rem;
    --sf-input-padding-bottom: 0.625rem;
    --sf-input-padding-left: 1rem;

    --sf-input-number-btn-size: 2.875rem;
    --sf-input-number-icon-size: 1.5rem;
    --sf-input-number-label-padding-left: 0;

    --sf-input-box-shadow: none;

    --sf-input-border-style: solid;
    --sf-input-border-bottom-width: 1px;
    --sf-input-border-top-width: 1px;
    --sf-input-border-right-width: 1px;
    --sf-input-border-left-width: 1px;
    --sf-input-border: rgb(10 10 10);
    --sf-input-border-active: var(--primary-color);
    --sf-input-border-focus: var(--primary-color);
    --sf-input-border-disabled: var(--disabled-background);
    --sf-input-border-radius: var(--border-radius);

    --sf-input-background: rgb(255 255 255);
    --sf-input-background-active: rgb(251 251 251);
    --sf-input-background-focus: rgb(240 240 240);
    --sf-input-background-disabled: var(--disabled-background);

    --sf-input-transition-property: background, border, color;
    --sf-input-transition-timing-func: ease-in;
    --sf-input-transition-duration: 150ms;

    --sf-input-label-font-size: 0.875rem;
    --sf-input-label-line-height: 0.875rem;
    --sf-input-label-color: rgb(87, 87, 87);
    --sf-input-label-padding-top: 1rem;
    --sf-input-label-padding-bottom: 0.375rem;
    --sf-input-label-padding-left: var(--sf-input-padding-left);

    --sf-input-label-floating-opacity: 0.8;
    --sf-input-label-floating-font-size: 0.625rem;
    --sf-input-label-floating-top: 0.375rem;
    --sf-input-label-floating-padding-top: 0;

    --sf-input-error-padding-x: 0.125rem;
    --sf-input-error-padding-y: 0.125rem;
    --sf-input-error-font-size: 0.75rem;
    --sf-input-error-color: var(--sf-status-color-danger);

    --sf-input-scroll-margin-top: 128px;
    --sf-input-scroll-margin-bottom: 128px;
    --sf-input-error-scroll-margin-top: 32px;
    --sf-input-error-scroll-margin-bottom: 32px;

    --sf-textarea-min-height: 150px;
    --sf-textarea-max-height: 400px;

    --sf-checkbox-label-color: rgb(0, 0, 0);
    --sf-checkbox-label-available-color: rgb(121, 121, 121);
    --sf-checkbox-label-available-font-size: 12px;
    --sf-checkbox-label-text-transform: none;
    --sf-checkbox-label-font-size: 0.875rem;
    --sf-checkbox-line-height: 1.125rem;
    --sf-checkbox-checkmark-background-color: rgb(0, 0, 0);
    --sf-checkbox-checkmark-border-color: rgb(0, 0, 0);
    --sf-checkbox-checkmark-border-radius: 0;

    --sf-radio-label-color: rgb(0, 0, 0);
    --sf-radio-label-available-color: rgb(121, 121, 121);
    --sf-radio-label-text-transform: none;
    --sf-radio-label-font-size: 0.875rem;
    --sf-radio-line-height: 1.125rem;
    --sf-radio-checkmark-background-color: rgb(0, 0, 0);
    --sf-radio-checkmark-border-color: rgb(0, 0, 0);

    --sf-switch-font-size: 1rem;
    --sf-switch-line-height: 22px;
    --sf-switch-background-color: rgb(215 215 215);
    --sf-switch-background-color-active: var(--primary-color);
    --sf-switch-background-color-checked: var(--primary-color);
    --sf-switch-btn-background-color: rgb(255 255 255);
    --sf-switch-btn-background-color-checked: rgb(255 255 255);
    --sf-switch-label-text-transform: none;
    --sf-switch-label-color: rgb(0 0 0);
    --sf-switch-label-weight: var(--font-weight-regular);
    --sf-switch-margin-x: 0;
    --sf-switch-margin-y: 0.375rem;

    --sf-select-checked-background: rgb(255 255 255);
    --sf-select-checked-font-weight: var(--font-weight-semi);

    --sf-input-number-min-width: 140px;

    --sf-input-number-error-padding-x: 0;
    --sf-input-number-error-padding-y: 0.25rem;

    --sf-form-stars-padding-y: 0.75rem;
    --sf-form-stars-padding-x: 1.25rem;
    --sf-form-stars-size: 1.25rem;
  }

  .control {
    width: 100%;
    color: var(--sf-input-color);
    font-size: var(--sf-input-font-size);
    font-family: var(--sf-input-font-family);
    font-weight: var(--sf-input-font-weight);
    line-height: var(--sf-input-line-height);
    padding: var(--sf-input-padding-top) var(--sf-input-padding-right) var(--sf-input-padding-bottom) var(--sf-input-padding-left);
    background-color: var(--sf-input-background);
    box-shadow: var(--sf-input-box-shadow);

    border-style: var(--sf-input-border-style);
    border-color: var(--sf-input-border);
    border-bottom-width: var(--sf-input-border-bottom-width);
    border-top-width: var(--sf-input-border-top-width);
    border-right-width: var(--sf-input-border-right-width);
    border-left-width: var(--sf-input-border-left-width);
    border-radius: var(--sf-input-border-radius);

    transition-property: var(--sf-input-transition-property);
    transition-timing-function: var(--sf-input-transition-timing-func);
    transition-duration: var(--sf-input-transition-duration);

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }

  .control__wrapper {
    position: relative;
    width: 100%;
    scroll-margin-top: var(--sf-input-scroll-margin-top);
    scroll-margin-bottom: var(--sf-input-scroll-margin-bottom);

    &--required {
      .control__label,
      .checkbox__label,
      .switch__label {
        &:after {
          content: "*";
          color: var(--sf-input-error-color);
        }
      }
    }

    .control__content {
      position: relative;

      .radio__container, .checkbox__container {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .radio__input, .checkbox__input {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;

          &:focus-visible ~ .checkmark {
            background-color: var(--sf-input-background-focus);
            border-color: var(--sf-input-border-focus);
            outline: 2px solid var(--sf-input-border-focus);
          }

          &:active ~ .checkmark {
            background-color: var(--sf-input-background-active);
            border-color: var(--sf-input-border-active);
          }

          &:checked ~ .checkmark:after {
            display: block;
          }

          &:disabled {
            & ~ .checkmark {
              background-color: var(--sf-input-background-disabled);
              border-color: var(--sf-input-border-disabled);
              pointer-events: none;
              cursor: not-allowed;

              &:after {
                background-color: var(--sf-input-border-disabled);
              }
            }

            & ~ .radio__label,
            & ~ .checkbox__label {
              color: var(--sf-input-color-disabled);
              pointer-events: none;
              cursor: not-allowed;
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: none;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .control__label {
      display: inline-block;
      color: var(--sf-input-label-color);
      font-size: var(--sf-input-label-font-size);
      padding-left: var(--sf-input-padding-left);
      padding-bottom: var(--sf-input-label-padding-bottom);
      line-height: var(--sf-input-label-line-height);
      border-right-width: var(--sf-input-border-right-width);
      border-left-width: var(--sf-input-border-left-width);
      border-top-width: 0;
      border-bottom-width: 0;
      border-style: solid;
      border-color: transparent;
    }

    &--floating {
      .control__label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: var(--sf-input-label-font-size);
        padding-left: var(--sf-input-label-padding-left);
        margin: 0;
        padding-top: var(--sf-input-label-padding-top);
        transform-origin: 0 0;
        transition-timing-function: var(--sf-input-transition-timing-func);
        transition-property: opacity, font-size, top, padding-top;
        transition-duration: var(--sf-input-transition-duration);
        pointer-events: none;
      }

      .control {
        padding-top: var(--sf-input-floating-padding-top);
        padding-bottom: var(--sf-input-floating-padding-bottom);
      }

      .control:focus ~ .control__label,
      .control:not(:placeholder-shown) ~ .control__label,
      .control:focus ~ .control__label,
      .control:not(:placeholder-shown) ~ .control__label,
      .control:-webkit-autofill ~ .control__label,
      .control:focus ~ .control__label,
      .control:not(:placeholder-shown) ~ .control__label,
      .control:-webkit-autofill ~ .control__label {
        opacity: var(--sf-input-label-floating-opacity);
        font-size: var(--sf-input-label-floating-font-size);
        top: var(--sf-input-label-floating-top);
        padding-top: var(--sf-input-label-floating-padding-top);
      }
    }

    .control__error {
      color: var(--sf-input-error-color);
      font-size: var(--sf-input-error-font-size);
      padding: var(--sf-input-error-padding-y) var(--sf-input-error-padding-x);
      scroll-margin-top: var(--sf-input-error-scroll-margin-top);
      scroll-margin-bottom: var(--sf-input-error-scroll-margin-bottom);
    }

    &--error {
      select,
      input,
      textarea {
        border-color: var(--sf-input-error-color);
      }
    }

    select,
    input,
    textarea {
      &:disabled {
        color: var(--sf-input-color-disabled);
        background-color: var(--sf-input-background-disabled);
        border-color: var(--sf-input-border-disabled);
        cursor: not-allowed;
        pointer-events: none;
      }

      &:focus-visible {
        background-color: var(--sf-input-background-focus);
        border-color: var(--sf-input-border-focus);
        outline: 2px solid var(--sf-input-border-focus);
      }

      &:active {
        background-color: var(--sf-input-background-active);
        border-color: var(--sf-input-border-active);
      }
    }
  }
</style>
