<script setup lang="ts">
  import { ref, type PropType } from 'vue';
  import { $post, $patch, $delete, route, $refresh } from '@plenny/visitor';
  import type { CustomerShippingAddress, Country } from '$types';
  import { useBreakpoints } from '$storefront';
  import { trans } from '@plenny/translator';

  type AddressData = {
    apartment_nr: string | null;
    building_nr: string | null;
    city: string | null;
    company: string | null;
    country_code: string | null;
    default: boolean;
    email: string | null;
    phone: string | null;
    name: string | null;
    postcode: string | null;
    region: string | null;
    street: string | null;
  }

  type AddressInitial = {
    country_code: string | null,
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    addresses: { type: Array as PropType<CustomerShippingAddress[]>, required: true },
    countries: { type: Array as PropType<Country[]>, required: true },
  });

  const breakpoints = useBreakpoints();
  const show = ref(false);
  const initial = ref<AddressInitial>({ country_code: __default_country_code });

  function createAddress() {
    initial.value = { country_code: __default_country_code };
    show.value = true;
  }

  function editAddress(delivery: CustomerShippingAddress) {
    initial.value = delivery;
    show.value = true;
  }

  function destroyAddress(delivery: CustomerShippingAddress) {
    if (confirm(trans('Czy na pewno chcesz usunąć ten adres z zapisanych?'))) {
      $delete(route('web.account.address.delivery.destroy', { delivery })).then(() => {
        $refresh();
      });
    }
  }

  function storeAddress(data: AddressData) {
    return $post(route('web.account.address.delivery.store'), data).then(() => {
      show.value = false;
      initial.value = { country_code: __active_country_code };
      $refresh();
    });
  }

  function updateAddress(data: CustomerShippingAddress) {
    return $patch(route('web.account.address.delivery.update', { delivery: data.id }), data).then(() => {
      show.value = false;
      initial.value = { country_code: __active_country_code };
      $refresh();
    });
  }

  function onSubmit(data: CustomerShippingAddress) {
    if (data.id) {
      return updateAddress(data);
    } else {
      return storeAddress(data);
    }
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Adresy dostawy') }}
  </AccountMobileNavigation>
  <SfTile large class="account-delivery">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">
        {{ $t('Adresy dostawy') }}
      </h1>
    </template>
    <template #headerAction>
      <div class="account-delivery__new">
        <SfButton primary @click="createAddress()" class="btn-add-adress">
          {{ $t('Dodaj nowy') }}
        </SfButton>
      </div>
    </template>
    <template #default>
      <div v-if="addresses.length > 0" class="account-delivery__addresses">
        <SfTile class="account-delivery__address" shadow v-for="address in addresses" :selected="address.default" :default="address.default">
          <template v-if="address.default" #outer>
            <span class="account-delivery__address-default">{{ $t('Adres domyślny') }}</span>
          </template>
          <template #title>
            <h2 class="account-delivery__address-name">{{ address.name }}</h2>
          </template>
          <template #headerAction>
            <SfButton transparent square apparent danger @click="destroyAddress(address)" :aria-label="$t('Usuń adres dostawy')">
              <template #before>
                <SfIconTrash class="account-delivery__icon" />
              </template>
            </SfButton>
          </template>
          <template #default>
            <div class="account-delivery__address-data">
              <p v-if="address.first_line" class="account-delivery__address-first-line">{{ address.first_line }}</p>
              <p v-if="address.second_line" class="account-delivery__address-second-line">{{ address.second_line }}</p>
              <p v-if="address.third_line" class="account-delivery__address-third-line">{{ address.third_line }}</p>
              <p v-if="address.country_code" class="account-delivery__address-code">{{ address.country_code }}</p>
              <p v-if="address.email" class="account-delivery__address-email">{{ address.email }}</p>
              <p v-if="address.phone" class="account-delivery__address-phone">{{ address.phone }}</p>
            </div>
          </template>
          <template #footer>
            <SfButton transparent @click="editAddress(address)">
              {{ $t('Edytuj') }}
            </SfButton>
          </template>
        </SfTile>
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Nie masz jeszcze zapisanych adresów dostawy.') }}
      </SfDataPlaceholder>
    </template>
  </SfTile>
  <SfModal v-model:open="show">
    <AccountAddressShippingForm v-model:open="show" v-bind="{ initial, countries, onSubmit }" />
  </SfModal>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-delivery {
    &__addresses {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }

    &__address {
      display: flex;
      position: relative;
    }

    &__address-data {
      font-size: 0.875rem;
    }
  }

  @include media.query(xxl) {
    .account-delivery .account-delivery__addresses {
      grid-template-columns: 1fr;
    }
  }

  @include media.query(lg) {
    .account-delivery {
      &__new {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      &__address-name {
        font-size: 1.125rem;
      }

      &__address {
        .tile__outer {
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }
</style>
