<script lang="ts" setup>
  import { ref, onMounted, type PropType, type Ref, onUpdated, onBeforeUpdate, onBeforeUnmount } from 'vue';
  import type { Photo } from '@plenny/picture';
  import type { NavButtons } from '$types';
  import type { SwiperOptions } from 'swiper/types';
  import Swiper from 'swiper';
  import { Navigation, Pagination } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  type Item = {
    files: string[];
    id: number;
    link: string;
    photos: Photo[];
    aria_label: string;
    header: string | null;
    header_type: string | null;
  }

  const props = defineProps({
    items: { type: Array as PropType<Item[]>, required: true },
    parameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlPagination = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;

  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      loop: true,
      ...props.parameters,
      pagination: {
        el: htmlPagination.value,
        clickable: true,
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel banner-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container banner-carousel__container">
      <div class="swiper-wrapper">
        <div v-for="item in items" class="swiper-slide items-carousel__slide banner-carousel__slide">
          <MediaPicture :photo="item.photos" class="banner-carousel__media" type="background" sizes="2800x800 2x min 992, 1400x400 min 992, 1984x568 2x min 576, 992x284 min 576, 1152x330 2x, 576x165 " />
          <SfHeading v-if="item.header" :type="item.header_type || 'span'" v-html="item.header" larger class="banner-carousel__heading" />
          <a v-if="item.link" :href="item.link" :aria-label="item.aria_label" class="banner-carousel__link" />
        </div>
      </div>
      <div ref="htmlPagination" class="swiper-pagination" />
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style scoped lang="scss">
  .banner-carousel {
    width: 100%;

    &__container {
      height: 100%;
      width: 100%;
    }

    &__heading {
      position: absolute;
      top: 50%;
      left: 5rem;
      max-height: 100%;
      max-width: 50%;
      transform: translateY(-50%);
      text-align: left;
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    :deep(picture) {
      width: 100%;

      img {
        object-fit: cover;
        aspect-ratio: 7/2;
        height: 100%;
      }
    }

    @media(max-width: 992px) {
      &__heading {
        left: 1rem;
        font-size: 0.875rem;
      }
    }
  }
</style>

