<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import type { Brand, ProductCategory } from '$types';

  interface MetaSource {
    ean: string | null;
    sku: string | null;
    mpn: string | null;
  }

  const props = defineProps({
    product: { type: Object as PropType<MetaSource>, required: true },
    variant: { type: Object as PropType<MetaSource>, required: true },
    brand: { type: Object as PropType<Brand>, required: false },
    category: { type: Object as PropType<ProductCategory>, required: false },
  });

  const ean = computed(() => props.variant.ean || props.product.ean);
  const sku = computed(() => props.variant.sku || props.product.sku);
  const mpn = computed(() => props.variant.mpn || props.product.mpn);

</script>
<template>
  <div class="meta">
    <dl class="wrapper">
      <div v-if="brand" class="info brand">
        <dt class="property">
          {{ $t('Marka') + ':' }}
        </dt>
        <dd class="value">
          <VisitorLink :href="route('web.product.index', { brands: [brand.id] })">
            {{ brand.name }}
          </VisitorLink>
        </dd>
      </div>
      <div v-if="category" class="info category">
        <dt class="property">
          {{ $t('Kategoria') + ':' }}
        </dt>
        <dd class="value">
          <VisitorLink :href="route('web.product.category', { category })">
            {{ category.title }}
          </VisitorLink>
        </dd>
      </div>
      <div v-if="ean" class="info ean">
        <dt class="property">
          {{ $t('EAN') + ':' }}
        </dt>
        <dd class="value">
          {{ ean }}
        </dd>
      </div>
      <div v-if="sku" class="info sku">
        <dt class="property">
          {{ $t('SKU') + ':' }}
        </dt>
        <dd class="value">
          {{ sku }}
        </dd>
      </div>
      <div v-if="mpn" class="info mpn">
        <dt class="property">
          {{ $t('Kod producenta') + ':' }}
        </dt>
        <dd class="value">
          {{ mpn }}
        </dd>
      </div>
    </dl>
  </div>
</template>
<style scoped lang="scss">
  .meta .wrapper {
    display: flex;
    flex-direction: column;

    @media(hover: hover) {
      a:hover {
        text-decoration: underline;
      }
    }

    .info {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;

      .property {
        font-weight: var(--font-weight-semi);
      }
    }
  }
</style>
