<script lang="ts" setup>
  import { trans } from '@plenny/translator';
  import type { PropType } from 'vue';
  import type { Breadcrumb, Section } from '$types';

  defineProps({
    module: { type: Object, required: true },
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: false, default: [] },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    departments: { type: Array, required: true },
    main: { type: Object, required: true },
  });

  const days = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };
</script>
<template>
  <main class="container contact">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
    <SectionRenderer :sections="sections" placement="before" />
    <section class="contact__wrapper">
      <div class="contact__form-wrapper">
        <header>
          <SfHeading larger type="h1">
            {{ module.title }}
          </SfHeading>
          <div v-if="module.description_short" class="contact__description" v-html="module.description_short" />
        </header>
        <SfContactForm :departments="departments" />
      </div>
      <div class="contact__data-wrapper">
        <section class="contact__data-wrapper-content">
          <SfHeading type="h2">
            {{ $t('Skontaktuj się z nami') }}
          </SfHeading>
          <div class="contact__data-wrapper-contact-flex ">
            <a v-if="main.phone" :href="`tel:${main.phone}`" class="contact__data-wrapper-link">
              <IconPhone />
              <span v-html="main.phone" />
            </a>
            <a v-if="main.email" :href="`mailto:${main.email}`" class="contact__data-wrapper-link">
              <IconEnvelope />
              <span v-html="main.email" />
            </a>
          </div>
        </section>
        <section class="contact__data-wrapper-content">
          <SfHeading type="h2">
            {{ $t('Adres') }}
          </SfHeading>
          <div class="contact__department-data">
            <a :href="main.maps || '#'" :title="$t('Zobacz na mapach')" class="contact__data-wrapper-address" rel="noreferrer nofollow noopener" target="_blank">
              <span class="contact__data-address-icon">
                <IconGeoAlt />
              </span>
              <p class="contact__data-address">
                <span v-if="main.name" v-text="main.name" />
                <span v-if="main.first_line" v-text="main.first_line" />
                <span v-if="main.second_line" v-text="main.second_line" />
                <span v-if="main.third_line" v-text="main.third_line" />
              </p>
            </a>
            <div class="contact__hours-wrapper">
              <SfHeading type="h3">
                {{ $t('Godziny otwarcia') + ':' }}
              </SfHeading>
              <div class="contact__hours">
                <template v-for="day in ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']">
                  <div v-if="main.hours[day]" class="contact__day">
                    <span><b>{{ days[day] }}</b></span>
                    <span class="contact__day-value" v-if="main.hours[day].closed">{{ $t('zamknięte') }}</span>
                    <span class="contact__day-value" v-else>{{ main.hours[day].from }} - {{ main.hours[day].to }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <SectionRenderer :sections="sections" placement="after" />
  </main>
</template>
<style lang="scss" scoped>
  .contact {

    &__wrapper {
      display: grid;
      grid-template-columns:repeat(2, 1fr);
      gap: 2rem;
    }

    &__form-wrapper,
    &__data-wrapper {
      background-color: var(--color-white);
    }

    &__form-wrapper,
    &__data-wrapper,
    &__data-wrapper-content,
    &__data-wrapper-contact-flex,
    &__department-data,
    &__hours-wrapper,
    &__data-address,
    &__hours {
      display: flex;
      flex-direction: column;
    }

    &__form-wrapper {
      gap: 2rem;
      padding: 2rem;

      header {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    &__data-wrapper {
      gap: 2rem;
      padding: 2rem;
    }

    &__data-wrapper-content {
      gap: 1rem;
    }

    &__data-wrapper-link {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
      padding: 0.25rem 0;
    }

    &__data-wrapper-address {
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;
    }

    &__data-address-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.25rem;
      width: 1.25rem;
    }

    &__department-data {
      gap: 2rem;
    }

    &__hours-wrapper {
      gap: 1rem;
    }

    &__hours {
      font-size: 0.875rem;
    }

    &__day {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0.25rem 0.5rem;

      &:nth-child(odd) {
        background-color: var(--secondary-background);
      }

      b {
        font-weight: var(--font-weight-semi);
      }
    }

    &__day-value {
      text-align: right;
    }

    @media (max-width: 992px) {
      &__wrapper {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
      &__form-wrapper {
        gap: 1rem;
        padding: 1rem 0.625rem;

        header {
          gap: 1rem;
        }
      }

      &__data-wrapper {
        gap: 1.5rem;
        padding: 1rem 0.625rem;
      }
      &__data-wrapper-content {
        gap: 0.75rem
      }
    }
  }
</style>
