<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  type Option = {
    label: string,
    value: string | number,
  }

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [String, Number, Boolean, Array], required: false },
    modelValue: { type: [String, Number, Boolean, Array], required: false },
    defaultValue: { type: [String, Number, Boolean, Array], required: false, default: null },
    placeholder: { type: [String, Boolean], required: false, default: undefined },
    options: { type: Array as PropType<Option[]>, required: false, default: [] },
  });

  const { model, error } = useVisitorControl(props, emit);
</script>
<template>
  <SfControlWrapper floating v-bind="{ id, name, label, required, error }">
    <select class="control select" v-model="model" v-bind="{ name, required, ...$attrs}" :id="id || name">
      <option v-if="placeholder || placeholder === undefined" key="required" :disabled="required" :value="null">
        {{ placeholder || (required ? 'Wybierz wartość' : 'Brak') }}
      </option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </SfControlWrapper>
</template>
<style lang="scss">
  :root {
    --sf-select-control-arrow: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  }

  .select {
    padding-right: calc(var(--sf-input-padding-right) + 2rem);
    background-image: var(--sf-select-control-arrow);
    background-repeat: no-repeat;
    background-position: right var(--sf-input-padding-right) center;
    background-size: 1rem;
    cursor: pointer;
    appearance: none;

    & + label {
      --sf-input-label-color: var(--sf-input-color);
    }

    option:checked {
      font-weight: var(--sf-select-checked-font-weight);
      background-color: var(--sf-select-checked-background);
    }
  }
</style>
