<script lang="ts" setup>
  import { useVisitorControl } from '@plenny/visitor';
  import { ref, type PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [String, Number], required: false },
    modelValue: { type: [String, Number], required: false },
    defaultValue: { type: [String, Number], required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
  });

  const htmlControl = ref();

  function increase() {
    htmlControl.value.stepUp();
    htmlControl.value.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
    htmlControl.value.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));
  }

  function decrease() {
    htmlControl.value.stepDown();
    htmlControl.value.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
    htmlControl.value.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));
  }

  const { model, error } = useVisitorControl(props, emit);
</script>

<template>
  <SfControlWrapper v-bind="{ id, name, label, required, error }" class="control__wrapper--number">
    <SfButton class="btn--minus" rounded square transparent @click="!disabled && decrease()" :aria-label="$t('Odejmij')">
      <SfIconMinus />
    </SfButton>
    <input ref="htmlControl" v-model="model" class="control number" type="number" v-bind="{ name, required, disabled,  ...$attrs }" :id="id || name">
    <SfButton class="btn--plus" rounded square transparent @click="!disabled && increase()" :aria-label="$t('Dodaj')">
      <SfIconPlus />
    </SfButton>
  </SfControlWrapper>
</template>

<style lang="scss">
  .control.number {
    min-width: var(--sf-input-number-min-width);
    height: var(--sf-input-number-btn-size);
    text-align: center;
    font-weight: var(--sf-input-number-font-weight, var(--font-weight-semi));
    -moz-appearance: textfield;
    --sf-input-padding-right: var(--sf-input-number-btn-size, --sf-input-padding-right);
    --sf-input-padding-left: var(--sf-input-number-btn-size, --sf-input-padding-left);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .control__wrapper--number {

    .control__label {
      padding-left: var(--sf-input-number-label-padding-left);
    }

    .control__content {
      .btn--plus,
      .btn--minus {
        position: absolute;
        top: 50%;
        height: calc(var(--sf-input-number-btn-size) - (var(--sf-input-border-top-width) + var(--sf-input-border-bottom-width)));
        width: var(--sf-input-number-btn-size);
        font-size: var(--sf-input-number-icon-size);
        transform: translateY(-50%);
        aspect-ratio: 1;
        vertical-align: middle;
      }

      .btn--plus {
        right: var(--sf-input-border-right-width);
      }

      .btn--minus {
        left: var(--sf-input-border-left-width);
      }
    }

    .control__error {
      padding: var(--sf-input-number-error-padding-y) var(--sf-input-number-error-padding-x);
    }
  }
</style>

