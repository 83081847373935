<script setup lang="ts">
  import { ref, type PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  type Brand = {
    id: number;
    name: string;
    slug: string;
    photo: string | null;
  }

  defineProps({
    header: String,
    brands: { type: Array as PropType<Brand[]>},
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 4.5,
        spaceBetween: 16,
        slidesOffsetBefore: 10,
      },
      576: {
        slidesPerView: 5.5,
        spaceBetween: 16,
        slidesOffsetBefore: 10,
      },
      768: {
        slidesPerView: 6.5,
        spaceBetween: 16,
        slidesOffsetBefore: 10,
      },
      992: {
        slidesPerView: 8,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
      },
    },
  });
</script>
<template>
  <SfSection :title="header" :link="route('web.brand.index')">
    <SfBrandsCarousel :brands :parameters  />
  </SfSection>
</template>
