<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { $redirect } from '@plenny/visitor';
  import { trans } from '@plenny/translator';
  import type { PaginationLink } from '$types';

  const props = defineProps({
    lastPage: { type: Number, required: true },
    currentPage: { type: Number, required: true },
    links: { type: Array as PropType<PaginationLink[]>, required: true },
  });

  const emit = defineEmits([
    'redirect',
  ]);

  function onClick(url: string | null, page: number) {
    let pageToRedirect = page;

    // if === 0 that means user clicked prev button, if === props.lastPage + 1 that means user clicked next button
    if (page === 0) pageToRedirect = props.currentPage - 1;
    if (page === props.lastPage + 1) pageToRedirect = props.currentPage + 1;

    emit('redirect', pageToRedirect);
    if (url) $redirect(url);
  }

  function getAriaLabel(label: string) {
    const numeric = Number(label);
    if (isNaN(numeric)) {
      if (label === '&lsaquo;') return trans('Przejdź do poprzedniej strony');
      else if (label === '&rsaquo;') return trans('Przejdź do następnej strony');
    } else {
      return trans('Przejdź do strony: :label', { label });
    }
  }

</script>
<template>
  <nav v-if="lastPage > 1" aria-label="Paginacja" class="pagination" role="navigation">
    <ul class="pagination__elements">
      <li v-for="(link, index) in links" class="pagination__item">
        <SfButton
          :aria-current="link.active"
          :aria-label="getAriaLabel(link.label)"
          :disabled="!link.url"
          :primary="link.active"
          rounded
          square
          @click="onClick(link.url, index)"
        >
          <span v-if="index > 0 && index < links.length - 1" class="pagination__label" v-html="link.label"></span>
          <SfIconChevronLeft v-else-if="index === 0" class="pagination__label" />
          <SfIconChevronRight v-else-if="index === links.length - 1" class="pagination__label" />
        </SfButton>
      </li>
    </ul>
  </nav>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-pagination-gap: 0.75rem;
    --sf-pagination-margin-y: 1.875rem;
    --sf-pagination-margin-x: 0;
  }

  .pagination {
    &__elements {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      gap: var(--sf-pagination-gap);
      margin: var(--sf-pagination-margin-y) var(--sf-pagination-margin-x);
    }

    &__label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
    }

    @include media.query(lg) {
      &__item {
        display: none;

        &:first-child, &.primary, &:last-child {
          display: flex;
        }
      }
    }
  }
</style>
