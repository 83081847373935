<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  const shared = useShared();

  const days = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };
</script>
<template>
  <footer class="footer">
    <div class="container footer__content">
      <div v-if="shared.department" class="footer__block footer__block--contact">
        <SfLogo class="footer__logo" />
        <div class="footer__contact-content">
          <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone">
            <IconPhone />
            <span v-html="shared.department.phone" />
          </a>
          <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="footer__mail">
            <IconEnvelope />
            <span v-html="shared.department.email" />
          </a>
          <a :href="shared.department.maps || '#'" class="footer__address" target="_blank">
            <IconGeoAlt />
            <span class="footer__address-content">
              <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
              <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
              <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
            </span>
          </a>
        </div>
      </div>
      <Collapsible v-for="group in shared.menu.menu_bottom">
        <template #header>
          <SfHeading v-if="group.name">{{ group.name }}</SfHeading>
        </template>
        <template #content>
          <div class="footer__block footer__block--categories">
            <ul>
              <li v-for="item in group.children">
                <FooterNavItem :item="item" class="link" />
              </li>
            </ul>
          </div>
        </template>
      </Collapsible>
      <Collapsible>
        <template #header>
          <SfHeading>{{ $t('Godziny otwarcia') }}</SfHeading>
        </template>
        <template #content>
          <div class="footer__block">
            <div class="footer__hours">
              <template v-for="day in ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']">
                <div v-if="shared.department.hours[day]" class="footer__day">
                  <span><b>{{ days[day] }}</b></span>
                  <span v-if="shared.department.hours[day].closed">{{ $t('zamknięte') }}</span>
                  <span v-else>{{ shared.department.hours[day].from }}&nbsp;-&nbsp;{{ shared.department.hours[day].to }}</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </Collapsible>
    </div>
    <span class="footer__separator" />
    <div class="container footer__bottom">
      <div>
        <CopyrightMessage />
      </div>
      <div>
        <p>
          {{ $t('Realizacja') + ': ' }}<a class="bottom-link" href="https://promoznawcy.pl/" target="_blank">PROMOznawcy.pl</a>
        </p>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>
  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    box-shadow: var(--secondary-box-shadow-200);
    background-color: var(--color-white);

    &__content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      padding: 4rem 0;
      font-size: 0.875rem;

      ul {
        list-style: none;
      }

      a {
        padding: 0.25rem 0;
      }
    }

    &__address-content,
    &__block,
    &__contact-content,
    &__hours {
      display: flex;
      flex-direction: column;
    }

    &__block {
      gap: 1rem;

      ul {
        display: flex;
        flex-direction: column;

        li {
          display: inline-flex;
        }
      }
    }

    &__contact-content {
      gap: 0.5rem;
    }

    &__logo {
      width: 200px;
      height: auto;
    }

    &__phone,
    &__mail,
    &__address {
      font-size: 1rem;
      display: inline-flex;
      align-items: flex-start;
      gap: 0.5rem;

      svg {
        font-size: 1.25rem;
      }
    }

    &__separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-grey);
    }

    &__hours {
      gap: 0.125rem;
    }

    &__day {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      b {
        font-weight: var(--font-weight-semi);

        &::after {
          content: ":";
        }
      }
    }

    @media(hover: hover) {
      a:hover {
        text-decoration: underline;
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      font-size: 0.875rem;
    }


    @media(max-width: 992px) {
      margin-top: 2rem;

      &__block--contact {
        margin-bottom: 1rem;
      }

      &__content {
        padding: 3rem 1rem;
        grid-template-columns: 1fr;
        gap: 0;
      }

      &__bottom {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
      }

      &__block {
        gap: 0.5rem;
        width: 100%;
      }

      .heading {
        flex: none;
      }

      &__phone,
      &__mail,
      &__address {
        font-size: 0.875rem;

        svg {
          font-size: 1.125rem;
        }
      }
    }
  }
</style>
