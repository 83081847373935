<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import type { Section } from '$types';


  const props = defineProps({
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    placement: { type: String, required: false, default: 'main' },
  });

  const sections = computed(() => {
    return props.sections.filter((section) => {
      return section.placement === props.placement;
    });
  });
</script>
<template>
  <div v-if="sections.length > 0" class="sections-wrapper">
    <template v-for="section in sections" :key="section.id">
      <component :is="section.type" v-bind="section" />
    </template>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-section-renderer-gap: 2rem;
    --sf-section-renderer-font-size: 1rem;
    --sf-section-renderer-heading-font-size: 1.5rem;
    --sf-section-renderer-paragraph-font-size: 1rem;
    --sf-section-renderer-list-margin-left: 2.5rem;
    --sf-section-renderer-list-item-padding: 0.25rem 0;

    @include media.query(lg) {
      --sf-section-renderer-gap: 1rem;
      --sf-section-renderer-heading-font-size: 1.25rem;
    }
  }

  .sections-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--sf-section-renderer-gap);
  }

  .section-typography {
    font-size: var(--sf-section-renderer-font-size);

    h1, h2, h3, h4, h5, h6 {
      font-size: var(--sf-section-renderer-heading-font-size);
    }

    p {
      font-size: var(--sf-section-renderer-paragraph-font-size);
      text-align: justify;
    }

    ul, ol {
      margin-left: var(--sf-section-renderer-list-margin-left);

      li {
        padding: var(--sf-section-renderer-list-item-padding);
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
