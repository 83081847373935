<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import type { Country } from '$types';
  import { CartLayout } from '$components';
  import { Features } from '$features';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  const props = defineProps({
    countries: { type: Array as PropType<Country[]>, required: true },
    step: { type: Number, required: true },
    offer: { type: Boolean, required: true },
  });

  const canGuestsOrder = computed(() => {
    return Features.ordersByGuests.isEnabled() && !props.offer;
  });
</script>
<template>
  <div class="order order-account">
    <h1 class="order-account__header">
      {{ offer ? $t('Zaloguj się lub zarejestruj aby złożyć zapytanie') : $t('Zdecyduj jak chcesz złożyć zamówienie') }}
    </h1>
    <div class="order-account__wrapper">
      <SfTile large>
        <SfTile class="order-account__login" small>
          <template #title>
            <h2 class="checkout-heading order-account__heading order-account__heading--login">
              {{ $t('Mam już konto') }}
            </h2>
          </template>
          <template #default>
            <AccountSignInForm />
          </template>
        </SfTile>
        <div v-if="canGuestsOrder" class="order-account__separator">
          <span class="order-account__separator-span">
            {{ $t('lub') }}
          </span>
        </div>
        <SfTile v-if="canGuestsOrder" small class="order-account__guest">
          <template #title>
            <h2 class="checkout-heading order-account__heading order-account__heading--guest">
              {{ $t('Kupuje jako gość') }}
            </h2>
          </template>
          <template #default>
            <SfButton wide :href="route('web.cart.details.edit')">
              {{ $t('Kup bez rejestracji') }}
            </SfButton>
          </template>
        </SfTile>
      </SfTile>
      <SfTile large>
        <template #title>
          <h2 class="checkout-heading order-account__heading">
            {{ $t('Chcę założyć nowe konto') }}
          </h2>
        </template>
        <AccountSignUpForm :countries>
          <template #info>
            <AccountSignUpAdvantages />
          </template>
        </AccountSignUpForm>
      </SfTile>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .order-account {
    max-width: 1400px;

    &__header {
      text-align: center;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: start;
      gap: 4rem;
      margin: 40px auto 100px;

      .guest-text {
        font-size: 14px;
        margin-bottom: 25px;
      }
    }

    &__heading--guest,
    &__heading--login {
      margin-bottom: 2rem;
    }

    &__separator {
      position: relative;
      width: 100%;
      height: 1px;
      color: var(--color-secondary);
      text-align: center;
      margin: 1rem 0;
      background-color: var(--color-secondary);
    }

    &__separator-span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      font-size: 0.75rem;
      padding: 0 0.75rem;
      background-color: var(--sf-tile-background);
    }
  }

  @include media.query(lg) {
    .order-account {
      &__header {
        font-size: 1.5rem;
        margin: 1rem 0;
        padding: 0 0.625rem;
      }

      &__wrapper {
        grid-template-columns: 1fr;
        margin: 0;
        gap: 1rem;
      }

      &__heading {
        margin: 0 auto;
        font-size: 1.5rem;
      }

      &__login, &__guest {
        .tile__content {
          padding: 0;
        }
      }
    }
  }
</style>

