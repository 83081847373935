<script setup>
  import { ref, computed } from 'vue';
  import { useCart } from '$storefront';

  const show = ref(false);
  const { cart } = useCart();

  const total = computed(() => cart.value?.total_gross || 0);
  const currency = computed(() => cart.value?.currency_code);
  const decimals = computed(() => cart.value?.currency_decimals);
</script>
<template>
  <div class="mini-cart" @click="show = true" tabindex="0" @keydown.enter="show = true" :aria-label="$t('Otwórz koszyk')" role="button">
    <IconCartHeader />
    <SfMoney :code="currency" :decimals="decimals" :value="total" class="mini-cart__value" />
  </div>
  <SfDrawerCart v-model:open="show" />
</template>
<style lang="scss" scoped>
  .mini-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: 0.625rem;
    font-size: 1.5rem;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    transition-property: color;
    transition-timing-function: var(--transition-primary-timing-function);
    transition-duration: var(--transition-primary-duration);

    @media(hover: hover) {
      &:hover {
        color: var(--primary-color);
      }
    }

    &__value {
      font-size: 1rem;
    }
  }

  @media(max-width: 768px) {
    .mini-cart {
      gap: 0.375rem;
      font-size: 1.375rem;

      &__value {
        font-size: 0.875rem;
      }
    }
  }
</style>
