<script lang="ts" setup>
  import { ref, computed, type Ref } from 'vue';
  import { useWishlistProducts } from '$storefront';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    variant: { type: Number, required: true },
  });

  const variants: Ref<number[]> = useWishlistProducts();
  const open = ref(false);

  const saved = computed(() => {
    return variants.value.includes(props.variant);
  });

  const savedText = computed(() => {
    return saved.value ? trans('Produkt zapisany w ulubionych, dodaj do kolejnej  listy') : trans('Dodaj do ulubionych');
  });

</script>
<template>
  <div class="wishlist" @click.prevent.stop="open = true">
    <slot v-bind="{ saved }">
      <SfButton :class="{ saved }" class="wishlist__button" rounded square transparent :aria-label="savedText" :title="savedText">
        <SfIconHeartFill v-if="saved" />
        <SfIconHeart v-else />
      </SfButton>
    </slot>
    <SfModal v-model:open="open">
      <SfModalHeader v-model:open="open">
        {{ $t('Dodaj do ulubionych') }}
      </SfModalHeader>
      <WishlistPickerModal :variant="variant" @saved="open = false" />
    </SfModal>
  </div>
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  .wishlist {
    display: contents;
    font-size: 1.125rem;

    &__button {
      &:hover {
        color: var(--color-primary);
      }

      &.saved {
        color: var(--danger-color);

        &:hover {
          color: var(--danger-color-hover);
        }
      }
    }
  }
</style>
