<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { route } from '@plenny/visitor';

  const props = defineProps({
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    categories: { type: Array as PropType<{ title: string }[]> },
  });

  const back = computed(() => {
    if (props.parent) {
      return route('web.blog.category.show', { category: props.parent });
    } else if (props.category) {
      return route('web.blog.post.index');
    } else {
      return null;
    }
  });
</script>
<template>
  <div class="filters-blog">
    <SfHeading type="h2">
      {{ $t('Kategorie') }}
    </SfHeading>
    <div class="filters-blog__content">
      <VisitorLink v-if="back" :href="back" class="filters-blog__back">
        {{ $t('Wróć do') + ':' }}&nbsp;{{ parent?.title || $t('Wszystkie') }}
      </VisitorLink>
      <ul class="filters-blog__list">
        <li v-for="category in categories">
          <VisitorLink :href="route('web.blog.category.show', { category })">
            {{ category.title }}
          </VisitorLink>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">

  .filters-blog {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 2rem;
    background-color: var(--color-white);

    &__content {
      font-size: 0.875rem;
    }

    &__back {
      display: inline-flex;
      padding-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: var(--font-weight-semi);
      text-decoration: underline;
    }

    a {
      padding: 0.25rem 0;
      display: inline-flex;

      @media(hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        font-weight: var(--font-weight-bold);
        color: var(--primary-color);
      }
    }

    &__list {
      list-style: none;
    }

    @media(max-width: 992px) {
      width: 100%;
      padding: 1rem 0.625rem;
      gap: 0.5rem;

      &__list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 0 1rem;
      }
    }
  }
</style>
