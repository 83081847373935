<script setup lang="ts">
  import { computed } from 'vue';
  import { useRealizationTime } from '$storefront';


  const props = defineProps({
    variant: { type: Object, required: true },
    product: { type: Object, required: true },
  });

  const realizationFrom = computed(() => props.variant.realization_from || props.product.realization_from);
  const realizationTo = computed(() => props.variant.realization_to || props.product.realization_to);

  const realization = computed(() => useRealizationTime({
    realization_from: realizationFrom.value,
    realization_to: realizationTo.value,
  }));
</script>

<template>
  <dl v-if="realization" class="product-realization-time">
    <dt class="product-realization-time__term">
      <SfIconClock />
      {{ $t('Czas realizacji') + ':' }}
    </dt>
    <dd class="product-realization-time__details">
      {{ realization }}
    </dd>
  </dl>
</template>

<style scoped lang="scss">
  .product-realization-time {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    flex-wrap: wrap;
    font-size: 0.75rem;

    &__term {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        font-size: 0.875rem;
      }
    }

    &__details {
      font-weight: var(--font-weight-bold);
    }
  }

</style>