<script setup lang="ts">
  import { ref } from 'vue';
  import type { PropType } from 'vue';
  import type { Section, Breadcrumb } from '$types';
  import type { Photo } from '@plenny/picture';

  type Category = {
    title: string | null
  }

  type Author = {
    name: string | null
  }

  type Post = {
    title: string | null;
    description_short: string | null;
    date: string | null;
    category: Category | null;
    author: Author | null;
    photos: Photo[];
  }

  defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: false, default: [] },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    recommended: { type: Array, required: false, default: [] },
    post: { type: Object as PropType<Post>, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesOffsetBefore: 10,
        slidesPerView: 1.5,
      },
      567: {
        slidesOffsetBefore: 10,
        slidesPerView: 2,
      },
      992: {
        slidesOffsetBefore: 0,
        slidesPerView: 3,
      },
    },
  });
</script>
<template>
  <main class="container blog-article">
    <article class="blog-article__article">
      <div class="blog-article__title">
        <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
        <header class="blog-article__title-container">
          <MediaPicture :photo="post.photos[0]" sizes="1080x800 2x, 540x400" type="banner" class="blog-article__photo" />
          <div class="blog-article__header">
            <SfHeading type="h1" larger class="blog-article__heading" v-html="post.title" />
            <div class="blog-article__description" v-html="post.description_short" />
            <div class="blog-article__meta-data">
              <VisitorLink v-if="post.category" :href="route('web.blog.category.show', { category: post.category })" class="blog-article__meta-data-item">
                <IconBookmark />
                {{ post.category.title }}
              </VisitorLink>
              <span v-if="post.date" class="blog-article__meta-data-item">
                <IconClock />
                <SfDateTime :value="post.date" />
              </span>
              <span v-if="post.author?.name" class="blog-article__meta-data-item">
                <IconClock />
                {{ post.author.name }}
              </span>
            </div>
          </div>
        </header>
      </div>
      <SectionRenderer :sections="sections" />
      <SfSection v-if="recommended.length > 0" class="blog-article__recommended" :title="$t('Przeczytaj także')">
        <SfArticlesCarousel :parameters :posts="recommended" />
      </SfSection>
    </article>
  </main>
</template>
<style lang="scss" scoped>
  .blog-article {
    --sf-breadcrumbs-padding-y: 0;
    --sf-breadcrumbs-padding-y-lg: 0;

    &__article,
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__title-container {
      display: flex;
      gap: 2rem;
    }

    &__article {
      max-width: 1100px;
      margin: 2rem auto;
      gap: 2rem;
    }

    &__title-container {
      gap: 1rem
    }

    &__description {
      font-size: 0.875rem;
    }

    &__meta-data {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 1rem;
    }

    &__header {
      gap: 0.5rem;
    }

    &__meta-data-item {
      display: inline-flex;
      align-items: center;
      gap: 0.25rem;
      font-size: 0.875rem;
    }

    &__title {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__photo :deep(img) {
      aspect-ratio: 540/400;
    }

    @media (max-width: 992px) {
      .blog-article {
        &__article {
          margin: 1rem auto;
        }

        &__recommended {
          padding: 1rem 0;
        }

        &__meta-data-item {
          font-size: 0.75rem;
        }

        &__title-container {
          padding: 0 0.625rem;
        }
      }
    }

    @media (max-width: 576px) {

      &__title-container {
        flex-direction: column;
      }
    }
  }
</style>
