<template>
  <svg width="201" height="39" viewBox="0 0 201 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_0_3)">
      <path d="M25.36 7.56H22.75V4.24C22.75 1.9 20.85 0 18.51 0H11.07C8.73 0 6.83 1.9 6.83 4.24V7.56H4.22C1.88 7.56 -0.0200043 9.46 -0.0200043 11.8V19.36C-0.0200043 20.46 0.879996 21.36 1.98 21.36C3.08 21.36 3.98 20.46 3.98 19.36V11.8C3.98 11.67 4.08 11.57 4.21 11.57H6.82V13.46C6.82 14.56 7.72 15.46 8.82 15.46C9.92 15.46 10.82 14.56 10.82 13.46V11.57H18.72V13.46C18.72 14.56 19.62 15.46 20.72 15.46C21.82 15.46 22.72 14.56 22.72 13.46V11.57H25.33C25.46 11.57 25.56 11.68 25.56 11.8V19.36C25.56 20.46 26.46 21.36 27.56 21.36C28.66 21.36 29.56 20.46 29.56 19.36V11.8C29.56 9.46 27.66 7.56 25.32 7.56H25.36ZM18.75 7.56H10.85V4.24C10.85 4.11 10.95 4.01 11.08 4.01H18.52C18.65 4.01 18.75 4.12 18.75 4.24V7.56Z" fill="url(#paint0_linear_0_3)" />
      <path d="M27.6 23.29C26.5 23.29 25.6 24.19 25.6 25.29C25.6 31.8 19.78 34.11 14.8 34.11C9.82 34.11 4 31.8 4 25.29C4 24.19 3.1 23.29 2 23.29C0.9 23.29 0 24.19 0 25.29C0 32.96 5.95 38.12 14.8 38.12C23.65 38.12 29.6 32.97 29.6 25.29C29.6 24.19 28.7 23.29 27.6 23.29Z" fill="#E6007E" />
      <path d="M51.03 35.21C49.2 35.21 46.94 35.02 45.48 34.65C43.13 34.04 43.13 32.67 43.13 31.17V8.03C43.13 6.43 43.13 5.21 45.48 4.55C46.75 4.17 49.2 3.99 51.17 3.99C61.99 3.99 68.01 9.68 68.01 19.51C68.01 29.34 61.75 35.22 51.03 35.22V35.21ZM51.27 30.74C58.75 30.74 62.7 26.7 62.7 19.64C62.7 12.58 58.66 8.45 51.27 8.45C50.33 8.45 49.25 8.54 48.26 8.69V30.51C49.25 30.65 50.33 30.75 51.27 30.75V30.74Z" fill="#646363" />
      <path d="M89.97 31.54C89.97 32.29 89.5 32.95 88.51 33.56C86.72 34.69 84.51 35.25 81.93 35.25C75.44 35.25 71.58 31.16 71.58 24.43C71.58 17.7 75.39 13.38 81.32 13.38C86.87 13.38 90.49 17.28 90.49 22.69C90.49 25.65 89.27 25.98 87.48 25.98H76.62C77.09 29.08 78.83 30.92 82.17 30.92C83.63 30.92 84.99 30.59 86.12 29.93C86.78 29.55 87.3 29.27 87.86 29.27C89.32 29.27 89.98 30.59 89.98 31.53L89.97 31.54ZM76.57 22.27H85.55C85.55 19.45 84.09 17.43 81.22 17.43C78.59 17.43 76.99 19.17 76.56 22.27H76.57Z" fill="#646363" />
      <path d="M100.13 15.69V16.72C101.4 14.79 103.61 13.52 106.67 13.52C109.26 13.52 111.37 14.32 112.74 16.34C113.63 17.66 114.06 19.49 114.06 21.84V33.03C114.06 34.39 113.21 35.1 111.52 35.1C109.83 35.1 108.98 34.39 108.98 33.03V22.68C108.98 21.41 108.79 20.28 108.23 19.48C107.52 18.45 106.4 17.98 104.89 17.98C102.82 17.98 101.36 18.92 100.42 20.14V33.03C100.42 34.39 99.57 35.1 97.88 35.1C96.19 35.1 95.34 34.39 95.34 33.03V15.69C95.34 14.33 96.14 13.62 97.79 13.62C99.53 13.62 100.19 14.47 100.14 15.69H100.13Z" fill="#646363" />
      <path d="M129.24 33.05C129.24 34.41 128.39 35.12 126.65 35.12C124.91 35.12 124.06 34.41 124.06 33.05V8.88H116.58C115.22 8.88 114.51 8.13 114.51 6.58C114.51 5.03 115.21 4.32 116.58 4.32H136.71C138.07 4.32 138.78 5.07 138.78 6.58C138.78 8.09 138.07 8.88 136.71 8.88H129.23V33.05H129.24Z" fill="#646363" />
      <path d="M155.44 31.54C155.44 32.29 154.97 32.95 153.98 33.56C152.19 34.69 149.98 35.25 147.4 35.25C140.91 35.25 137.05 31.16 137.05 24.43C137.05 17.7 140.86 13.38 146.79 13.38C152.34 13.38 155.96 17.28 155.96 22.69C155.96 25.65 154.74 25.98 152.95 25.98H142.09C142.56 29.08 144.3 30.92 147.64 30.92C149.1 30.92 150.46 30.59 151.59 29.93C152.25 29.55 152.77 29.27 153.33 29.27C154.79 29.27 155.45 30.59 155.45 31.53L155.44 31.54ZM142.04 22.27H151.02C151.02 19.45 149.56 17.43 146.69 17.43C144.06 17.43 142.46 19.17 142.03 22.27H142.04Z" fill="#646363" />
      <path d="M170.11 30.89C171.29 30.89 172.46 30.51 173.36 29.95C173.88 29.62 174.35 29.34 174.91 29.34C176.27 29.34 177.12 30.52 177.12 31.64C177.12 32.49 176.6 33.1 175.9 33.57C174.35 34.7 172 35.26 169.97 35.26C164.09 35.26 159.62 30.93 159.62 24.35C159.62 17.77 163.9 13.49 169.97 13.49C171.95 13.49 174.25 13.96 175.9 15.09C176.75 15.65 177.12 16.27 177.12 16.97C177.12 18.05 176.37 19.42 174.96 19.42C174.44 19.42 174.07 19.18 173.36 18.81C172.33 18.2 171.38 17.87 170.11 17.87C166.72 17.87 164.84 20.32 164.84 24.27C164.84 28.22 166.67 30.9 170.11 30.9V30.89Z" fill="#646363" />
      <path d="M200.16 33.05C200.16 34.41 199.31 35.12 197.62 35.12C195.93 35.12 195.08 34.41 195.08 33.05V22.7C195.08 21.43 194.89 20.3 194.33 19.5C193.62 18.47 192.5 18 190.99 18C188.92 18 187.46 18.94 186.52 20.16V33.05C186.52 34.41 185.67 35.12 183.98 35.12C182.29 35.12 181.44 34.41 181.44 33.05V4.92C181.44 3.56 182.29 2.85 183.98 2.85C185.67 2.85 186.52 3.56 186.52 4.92V16.4C187.74 14.66 189.91 13.53 192.78 13.53C195.37 13.53 197.48 14.33 198.85 16.35C199.74 17.67 200.17 19.5 200.17 21.85V33.04L200.16 33.05Z" fill="#646363" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_0_3" x1="8.22" y1="4.58" x2="17.66" y2="19.9" gradientUnits="userSpaceOnUse">
        <stop stop-color="#006FB9" />
        <stop offset="1" stop-color="#302C83" />
      </linearGradient>
      <clipPath id="clip0_0_3">
        <rect width="200.16" height="38.11" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>