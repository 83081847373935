<script lang="ts" setup>
  import { $delete, $redirect, route } from '@plenny/visitor';
  import { useCart } from '$storefront';
  import { UnitFormatter } from '$support';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const { items, cart } = useCart();

  function onDestroy(item) {
    $delete(route('api.v1.web.cart.item.destroy', { item }));
  }

  function onGoToCart() {
    open.value = false;
    $redirect(route('web.cart.show'));
  }

  function onListCardClick(product) {
    open.value = false;
    $redirect(route('web.product.show', { slug: product.slug }));
  }

  function formatItemUnit(item) {
    return UnitFormatter.short(item.stock_unit, item.amount);
  }
</script>
<template>
  <SfDrawer v-model:open="open" class="drawer-cart">
    <SfDrawerHeader v-model:open="open">
      {{ $t('Koszyk') }}
    </SfDrawerHeader>

    <div class="drawer-cart__products">
      <template v-if="items.length > 0">
        <SfListCard interactive v-for="item in items" :key="item.id"  class="drawer-cart__product" small @click="onListCardClick(item)">
          <template #image>
            <MediaPicture :photo="item.photo" class="drawer-cart__product-image" sizes="150x150 2x, 75x75" />
          </template>
          <template #default>
            <header>
              <h3 class="drawer-cart__product-title">{{ item.variant_title }}</h3>
              <p class="drawer-cart__product-sku">SKU: {{ item.variant_sku }}</p>
            </header>
            <section class="drawer-cart__product-details">
              <p class="drawer-cart__product-price">
                <SfProductPrice :price="item" />
              </p>
              <p class="drawer-cart__product-count" v-html="formatItemUnit(item)" />
            </section>
            <section>
              <SfProductOmnibus :price="item" />
            </section>
          </template>
          <template #icons>
            <SfButton danger square transparent @click.stop="onDestroy(item)" :aria-label="$t('Usuń produkt z koszyka')" :title="$t('Usuń produkt z koszyka')">
              <SfIconTrash />
            </SfButton>
          </template>
        </SfListCard>
      </template>
      <template v-else>
        <SfDataPlaceholder>
          <p>{{ $t('Brak produktów w koszyku.') }}</p>
        </SfDataPlaceholder>
      </template>
    </div>
    <OrderSummary v-if="items.length > 0" :order="cart" class="drawer-cart__summary">
      <SfButton :disabled="items.length <= 0" primary @click="onGoToCart">
        {{ $t('Przejdź do koszyka') }}
      </SfButton>
    </OrderSummary>
  </SfDrawer>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-cart-drawer-box-shadow: var(--secondary-box-shadow-100);
    --sf-cart-drawer-border: none;
    --sf-cart-drawer-border-width: 0;
    --sf-cart-drawer-border-style: none;

    --sf-cart-drawer-header-background: white;
    --sf-cart-drawer-header-padding-y: 1rem;
    --sf-cart-drawer-header-padding-x: 1rem;

    --sf-cart-drawer-padding-x: 0;
    --sf-cart-drawer-padding-y: 0;
    --sf-cart-drawer-gap-y: 0;

    --sf-cart-drawer-list-card-padding-y: 0.5rem;
    --sf-cart-drawer-list-card-padding-x: 0.5rem;
  }

  .drawer-cart {
    --sf-drawer-header-background: var(--sf-cart-drawer-header-background);
    --sf-drawer-header-padding-y: var(--sf-cart-drawer-header-padding-y);
    --sf-drawer-header-padding-x: var(--sf-cart-drawer-header-padding-x);

    --sf-drawer-padding-x: var(--sf-cart-drawer-padding-x);
    --sf-drawer-padding-y: var(--sf-cart-drawer-padding-y);
    --sf-drawer-gap-y: var(--sf-cart-drawer-gap-y);

    --sf-list-card-padding-y: var(--sf-cart-drawer-list-card-padding-y);
    --sf-list-card-padding-x: var(--sf-cart-drawer-list-card-padding-x);
    --sf-list-card-background-hover: var(--background-default);

    .drawer__header {
      position: sticky;
      top: 0;
      box-shadow: var(--sf-cart-drawer-box-shadow);
      border-bottom-color: var(--sf-cart-drawer-border);
      border-bottom-width: var(--sf-cart-drawer-border-width);
      border-bottom-style: var(--sf-cart-drawer-border-style);
      z-index: 9;
    }

    .order-cart-summary {
      position: sticky;
      bottom: 0;
      padding: 1rem;
      background-color: var(--color-white);
      z-index: 9;
      box-shadow: var(--sf-cart-drawer-box-shadow);
      border-top-color: var(--sf-cart-drawer-border);
      border-top-width: var(--sf-cart-drawer-border-width);
      border-top-style: var(--sf-cart-drawer-border-style);
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding: 1rem;
      flex-grow: 1;

      .list-card {
        flex: none;

        &__image {
          flex: none;
        }
      }
    }

    &__product-image img {
      width: 75px;
      aspect-ratio: 1;
    }

    &__product-title {
      font-size: 1rem;
    }

    &__product-sku {
      font-size: 0.75rem;
    }

    &__product-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    &__product-price {
      font-size: 1rem;
      color: var(--color-black);
      font-weight: var(--font-weight-bold);
    }

    &__product-count {
      font-size: 0.75rem;
    }
  }

  @include media.query(lg) {
    .drawer-cart {
      &__products {
        gap: 0.5rem;
        padding: 1rem 0.5rem;
        --sf-list-card-gap: 0.5rem
      }
    }
  }
</style>
