<script lang="ts" setup>
  import { useQuery } from '@plenny/visitor';
  import { computed } from 'vue';

  defineProps({
    categories: { type: Object, required: true },
  });

  const query = useQuery();

  const filteredQuery = computed(() => {
    return Object.fromEntries(Object.entries(query.value).filter(([k]) => k != 'page'));
  });
</script>
<template>
  <div class="categories">
    <div v-if="categories.parent" class="categories__back">
      <VisitorLink :href="url(categories.parent.url, filteredQuery)">
        {{ $t('cofnij do:') }}
        <span class="categories__parent-name">{{ categories.parent.name }}</span>
      </VisitorLink>
    </div>
    <div v-if="categories.category" class="categories__current">
      <VisitorLink :href="url(categories.category.url, filteredQuery)">
        {{ categories.category.name }}
      </VisitorLink>
    </div>
    <ul class="categories__list">
      <li v-for="category in categories.children" class="categories__item">
        <VisitorLink :href="url(category.url, filteredQuery)">
          <span class="categories__name">{{ category.name }}</span>
          <small class="categories__quantity">{{ category.products }}</small>
        </VisitorLink>
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-category-filters-color-hover: var(--color-primary);
    --sf-category-filters-back-font-size: 0.875rem;
    --sf-category-filters-font-size: 1rem;
    --sf-category-filters-active-font-weight: var(--font-weight-semi);
    --sf-category-filters-current-font-weight: var(--font-weight-bold);
    --sf-category-filters-current-font-size: 1rem;
    --sf-category-filters-current-color: var(--color-default);
  }

  .categories {
    display: flex;
    flex-direction: column;
    font-size: var(--sf-category-filters-font-size);

    &__back {
      font-size: var(--sf-category-filters-back-font-size);
      margin-bottom: 0.5rem;
    }

    &__parent-name {
      text-decoration: underline;
      margin-left: 0.375rem;
    }

    &__current {
      font-weight: var(--sf-category-filters-current-font-weight);
      font-size: var(--sf-category-filters-current-font-size);
      color: var(--sf-category-filters-current-color);
    }

    &__list {
      display: flex;
      flex-direction: column;
      list-style: none;
    }

    &__current + &__list {
      padding-left: 0.75rem;
    }

    a {
      display: inline-flex;
      padding: 0.25rem 0;
    }


    @media (hover: hover) {
      &__current,
      &__list {
        a:hover {
          text-decoration: underline;
        }
      }
    }

    &__item {
      font-size: 0.875rem;

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;


        &.active .categories__name {
          font-weight: var(--sf-category-filters-active-font-weight);
        }
      }
    }

    @media(max-width: 992px) {
      a {
        padding: 0.5rem 0;
      }
    }
  }
</style>
