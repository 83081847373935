<script setup lang="ts">
  import { ref, type PropType, watch } from 'vue';
  import type { Breadcrumb, Section, Brand, ProductCategory } from '$types';
  import { $redirect, route } from '@plenny/visitor';

  type ReviewsSummary = {
    1: { count: number, percent: number },
    2: { count: number, percent: number },
    3: { count: number, percent: number },
    4: { count: number, percent: number },
    5: { count: number, percent: number },
  }

  const props = defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
    sections: { type: Array as PropType<Section[]>, required: true },
    product: { type: Object, required: true },
    variant: { type: Object, required: true },
    brand: { type: Object as PropType<Brand>, required: true },
    category: { type: Object as PropType<ProductCategory>, required: false },
    recommended: Array,
    posts: Array,
    page: Object,
    review_available: { type: Boolean, required: true },
    reviews: { type: Object, required: true },
    reviews_summary: { type: Object as PropType<ReviewsSummary>, required: true },
  });
  const variant = ref(props.variant);
  const wrapperHtmlElement = ref();

  watch(() => variant.value.slug, (value, oldValue) => {
    if (oldValue && value !== oldValue) {
      $redirect(route('web.product.show', { slug: value }), true, false);
    }
  });

  watch(() => props.variant, (value) => {
    variant.value = value;
  });
</script>
<template>
  <div class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
  </div>
  <ShopProductViewBanner :wrapper="wrapperHtmlElement" :product :variant />
  <div ref="wrapperHtmlElement">
    <ShopProductViewDetails :brand :category :page :product v-model:variant="variant" />
  </div>
  <ShopProductViewDescription :product :recommended :reviews :sections :reviewAvailable="review_available" :reviewsSummary="reviews_summary" />
</template>