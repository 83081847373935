<script lang="ts" setup>
  import { onMounted, ref, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount, type PropType } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import type { Photo } from '@plenny/picture';

  type Category = {
    title: string | null,
    photo: Photo
  }

  defineProps({
    categories: { type: Array as PropType<Category[]>, required: true },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      slidesPerView: 1.5,
      breakpoints: {
        1: {
          slidesPerView: 2.5,
          spaceBetween: 16,
          slidesOffsetBefore: 10,
        },
        576: {
          slidesPerView: 3.5,
          spaceBetween: 16,
          slidesOffsetBefore: 10,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 16,
          slidesOffsetBefore: 0,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 16,
          slidesOffsetBefore: 0,
        },
        1400: {
          slidesPerView: 6,
          spaceBetween: 16,
          slidesOffsetBefore: 0,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel categories-carousel" v-if="categories.length > 0">
    <div ref="htmlElement" class="swiper items-carousel__container categories-carousel__container">
      <div class="swiper-wrapper">
        <div v-for="category in categories" class="swiper-slide items-carousel__slide categories-carousel__article">
          <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
            <div class="categories-carousel__image-wrapper">
              <MediaPicture :photo="category.photo" sizes="350x350 2x, 175x175" type="photo" />
            </div>
            <span v-if="category.title" class="categories-carousel__category-title">{{ category.title }}</span>
          </VisitorLink>
        </div>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style lang="scss" scoped>
  .categories-carousel {

    &__image-wrapper {
      width: 100%;
    }

    &__category-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      word-break: break-word;
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      flex-grow: 1;
      text-align: center;
      border: 1px solid var(--secondary-border);
      transition-property: border-color;
      transition-timing-function: var(--transition-primary-timing-function);
      transition-duration: var(--transition-primary-duration);
      border-radius: var(--border-radius);
      overflow: hidden;

      :deep(img) {
        aspect-ratio: 1;
        object-fit: contain;
      }

      @media(hover: hover) {
        &:hover {
          border-color: var(--priamry-border-hover);
        }
      }
    }


    @media(max-width: 768px) {
      &__category-title {
        font-size: 0.75rem;
      }

      :deep(img) {
        //height: 100px;
      }
    }
  }
</style>

