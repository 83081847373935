<script lang="ts" setup>
  import { ref } from 'vue';

  const open = ref(false);
</script>
<template>
  <div class="collapsible">
    <div class="collapsible__header-container" @click="open = !open">
      <div class="collapsible__header">
        <slot name="header" />
      </div>
      <SfIconChevronDown class="collapsible__icon" :class="{'collapsible__icon--open': open}" />
    </div>
    <div class="collapsible__content" :class="{'collapsible__content--open': open}">
      <div class="collapsible__content__inner">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --sf-collapsible-gap: 1rem;
    --sf-collapsible-border: none;
    --sf-collapsible-padding: 0;

    --sf-collapsible-icon-padding-lg: 0.5rem;
    --sf-collapsible-icon-height-lg: 2rem;
    --sf-collapsible-icon-width-lg: 2rem;

    --sf-collapsible-content-padding: 0;
    --sf-collapsible-content-padding-inner: 0;

    --sf-collapsible-input-background: var(--sf-input-background);

    @include media.query(lg) {
      --sf-collapsible-gap: 0;
      --sf-collapsible-content-padding-inner: 0 0 1rem;
      --sf-collapsible-content-padding: 0 0.5rem;
    }
  }

  .collapsible {
    display: flex;
    flex-direction: column;
    gap: var(--sf-collapsible-gap);
    border: var(--sf-collapsible-border);
    padding: var(--sf-collapsible-padding);
    width: 100%;

    @include media.query(lg) {
      border-bottom: 1px solid var(--secondary-border);
    }

    &__header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media.query(lg) {
        position: relative;
        cursor: pointer;
        padding: 0.75rem 0;
      }
    }

    &__icon {
      display: none;

      @include media.query(lg) {
        display: block;
        z-index: 2;
        width: var(--sf-collapsible-icon-width-lg);
        height: var(--sf-collapsible-icon-height-lg);
        padding: var(--sf-collapsible-icon-padding-lg);
        transition: transform 0.3s ease;

        &--open {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      padding: var(--sf-collapsible-content-padding);
      transition: max-height 0.6s ease;

      &__inner {
        padding: var(--sf-collapsible-content-padding-inner);
      }

      @include media.query(lg) {
        max-height: 0;
        overflow: hidden;

        &--open {
          max-height: 750px;
          overflow-y: auto;
        }
      }
    }
  }
</style>
