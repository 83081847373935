<script setup>
  import { useSession, route } from '@plenny/visitor';

  const session = useSession();

</script>
<template>
  <div class="header-account">
    <template v-if="!session.is_authenticated">
      <VisitorLink :href="route('web.account.session.create')" class="header-account__item" primary>
        {{ $t('Zaloguj się') }}
      </VisitorLink>
      |
      <VisitorLink :href="route('web.account.register.create')" class="header-account__item">
        {{ $t('Zarejestruj się') }}
      </VisitorLink>
    </template>
    <template v-else>
      <VisitorLink :href="route('web.account.show')" class="header-account__item">
        <IconPersonCircle />
        {{ $t('Konto') }}
      </VisitorLink>
    </template>
  </div>
</template>
<style lang="scss" scoped>
  .header-account {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    @media(hover: hover) {
      a:hover {
        text-decoration: underline;
      }
    }
  }
</style>
