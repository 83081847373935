<script setup lang="ts">
  import { $post, route } from '@plenny/visitor';

  type SignInData = {
    email: string;
    password: string;
    remember: boolean;
  }

  function actionLogin(data: SignInData) {
    return $post(route('web.account.session.store'), data);
  }
</script>
<template>
  <VisitorForm :submit="actionLogin" v-slot="{ submitting }" class="sign-in-form">
    <div class="sign-in-form__inputs">
      <SfFormInput :label="$t('E-mail')" type="email" name="email" id="sign-in-email" autocomplete="username" />
      <div class="sign-in-form__password">
        <SfFormInput :label="$t('Hasło')" type="password" name="password" autocomplete="current-password" id="sign-in-password" />
        <VisitorLink :href="route('web.account.password.forgotten.create')" class="sign-in-form__forget-password">
          {{ $t('Nie pamiętam hasła') }}
        </VisitorLink>
      </div>
    </div>
    <SfFormSwitch :label="$t('Zapamiętaj mnie')" name="remember" />
    <SfButton type="submit" primary :loading="submitting">
      {{ $t('Zaloguj się') }}
    </SfButton>
  </VisitorForm>
</template>

<style lang="scss">
  :root {
    --sf-sign-in-form-forget-font-size: 0.75rem;
    --sf-sign-in-form-forget-font-weight: var(--font-weight-semi);
    --sf-sign-in-form-forget-margin-top: 0.375rem;
    --sf-sign-in-form-forget-margin-right: var(--sf-input-padding-right, 0)
  }

  .sign-in-form {
    &__password,
    &__inputs {
      display: flex;
      flex-direction: column;
    }

    &__password {
      align-items: flex-end;
    }

    &__inputs {
      gap: var(--form-gap-y) var(--form-gap-x);
    }

    &__forget-password {
      font-size: var(--sf-sign-in-form-forget-font-size);
      font-weight: var(--sf-sign-in-form-forget-font-weight);
      margin-top: var(--sf-sign-in-form-forget-margin-top);
      margin-right: var(--sf-sign-in-form-forget-margin-right);
    }
  }
</style>
