<script setup>
  import { watch, computed, ref } from 'vue';
  import { useScrollWidth, useBreakpoints, useFixedActionsSpacer, useScrollDetector } from '$storefront';
  import { useShared, $delete, route, $refresh } from '@plenny/visitor';

  const { spacer, offset } = useFixedActionsSpacer();
  const scroll = useScrollWidth();
  const breakpoints = useBreakpoints();
  const shared = useShared();

  const isScrollingDown = ref(true);
  const isScrolled = ref(false);
  const scrollWidth = computed(() => scroll.value + 'px');
  const isComparator = computed(() => Object.keys(shared.value.comparator).length > 0);

  const backToTop = () => {
    document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  function actionChat() {
    window.Tawk_API?.toggle();
  }

  function clearComparator() {
    $delete(route('api.v1.web.comparator.destroy')).then(() => $refresh());
  }

  useScrollDetector({
    threshold: 8,
    callback({ down, scrolled }) {
      isScrollingDown.value = down;
      isScrolled.value = scrolled;
    },
  });

  watch(isScrollingDown, (value) => {
    if (value) {
      spacer.value -= 58;
    } else {
      spacer.value += 58;
    }
  });

</script>
<template>
  <div class="fixed-actions__buttons" :class="{'compare-bar': isComparator }">
    <SfButton primary rounded square class="chat fixed-actions__button" @click="actionChat" :aria-label="$t('Otwóz czat')">
      <IconChat />
    </SfButton>
    <SfButton primary rounded square class="back-to-top fixed-actions__button" v-if="isScrolled && !isScrollingDown" @click="backToTop"  :aria-label="$t('Powrót do góry strony')">
      <IconChevronUp />
    </SfButton>
  </div>
  <div class="comparator-bar" v-if="isComparator">
    <div class="comparator-bar__container container">
      <div class="comparator-bar__container-inner">
        <div class="comparator-bar__images">
          <MediaPicture sizes="100x100 2x, 50x50, 80x80 2x max 992, 40x40 max 992" v-for="item in shared.comparator" :photo="item.photo" />
        </div>
      </div>
      <SfButton small primary :href="route('web.comparator.index')" class="comparator-bar__compare-btn">
        {{ $t('Porównaj') }}
        <template #after>
          {{ Object.keys(shared.comparator).length }}
        </template>
      </SfButton>
      <SfButton transparent danger square rounded @click="clearComparator" :title="$t('Wyczyść porównanie')" :aria-label="$t('Wyczyść porównanie')">
        <SfIconTrash />
      </SfButton>
    </div>
  </div>
  <div class="bottom-nav-wrapper" v-if="!breakpoints.lg" :class="{ 'is-scrolling-down': isScrollingDown }">
    <BottomNavigation />
  </div>
</template>
<style scoped lang="scss">

  .comparator-bar,
  .back-to-top,
  .chat {
    --bottomOffset: v-bind(offset);
  }

  .fixed-actions__buttons {
    --space: v-bind(scrollWidth);
    --sf-btn-font-size: 1.25rem;

    position: fixed;
    bottom: 1rem;
    right: calc(var(--space) + 1rem);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    z-index: 9;

    &.compare-bar {
      bottom: 76px;
    }
  }

  .comparator-bar {
    --space: v-bind(scrollWidth);
    position: fixed;
    bottom: 0;
    right: var(--space);
    left: 0;
    height: 60px;
    display: flex;
    align-items: center;
    z-index: 10;
    box-shadow: var(--secondary-box-shadow-100);
    background-color: var(--color-white);

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    &__images {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 1rem;

      :deep(picture) {
        flex-grow: 0;
        flex-shrink: 0;

        img {
          aspect-ratio: 1;
        }
      }
    }

    &__compare-btn :deep(.btn__after) {
      background-color: var(--color-black);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.75rem;
      line-height: 1;
      min-width: 1.25rem;
      padding: 0.25rem 0.375rem;
      border-radius: 1.5rem;
      color: var(--color-white);
      transform: translate(-50%, -50%);
      font-weight: var(--font-weight-bold);
    }
  }

  .bottom-nav-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 58px;
    width: 100%;
    transform: translateY(0);
    box-shadow: var(--box-shadow-primary);
    background-color: var(--color-white);
    z-index: 10;
    transition: transform 0.3s;
  }

  .bottom-nav-wrapper.is-scrolling-down {
    transform: translateY(58px);
  }

  @media (max-width: 992px) {
    .fixed-actions__button,
    .comparator-bar {
      transform: translateY(calc(-1 * var(--bottomOffset)));
      transition: transform 0.3s;
    }

    .comparator-bar {
      &__container {
        padding: 0 0.625rem 0 0;
        gap: 0.625rem;
      }

      &__images {
        gap: 0.5rem;
        flex: 0 0 auto;

        :deep(img) {
          aspect-ratio: 1;
        }
      }

      &__container {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 2rem;
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          z-index: 5;
          pointer-events: none;
        }
      }

      &__container-inner {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
</style>
