<script setup lang="ts">
  import { ref, type PropType } from 'vue';
  import type { Section } from '$types';

  defineProps({
    product: { type: Object, required: true },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    recommended: { type: Array, required: false, default: [] },
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });

</script>
<template>
  <div class="container description-wrapper" v-if="sections.length > 0 || product.listAttributes.length > 0 || product.reviewsAverage > 0">
    <ShopProductViewDescriptionSection v-if="sections.length > 0" :title="$t('Opis')">
      <SectionRenderer :sections />
    </ShopProductViewDescriptionSection>

    <ShopProductViewDescriptionSection v-if="product.listAttributes.length > 0" :title="$t('Specyfikacja')">
      <ShopProductViewDescriptionSpecification :product />
    </ShopProductViewDescriptionSection>

    <ShopProductViewDescriptionSection :title="$t('Opinie')">
      <ShopProductViewDescriptionReviews :reviews :product :reviewAvailable :reviewsSummary />
    </ShopProductViewDescriptionSection>

    <ShopProductViewDescriptionSection :title="$t('Podobne produkty')" v-if="recommended.length > 0">
      <div class="products-wrapper">
        <SfProductsCarousel :products="recommended" :parameters />
      </div>
    </ShopProductViewDescriptionSection>
  </div>
</template>
<style scoped lang="scss">
  .description-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    padding: 1rem;
    background-color: var(--color-white);
    border-radius: var(--border-radius);

    @media (max-width: 992px) {
      margin: 1rem auto;
      padding: 1rem 0.625rem;
      gap: 1.5rem;
    }
  }
</style>
