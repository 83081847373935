<script setup>
  import { computed } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { useScrollWidth } from '$storefront';

  const shared = useShared();
  const scroll = useScrollWidth();

  const scrollWidth = computed(() => scroll.value + 'px');
</script>
<template>
  <div class="cat">
    <div class="cat-wrapper">
      <ul>
        <HeaderNavItem v-for="(item, index) in shared.categories" :item="item" :depth="0" :index="String(index)" category @close="$emit('close')" />
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .cat {
    --space: v-bind(scrollWidth);

    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 1400px;
    z-index: 99;

    .cat-wrapper {
      position: relative;

      > ul {
        overflow-y: auto;
        overscroll-behavior: contain;
        max-height: calc(100svh - 150px);
        list-style: none;
        width: 300px;
        padding: 10px 0 0;
        background-color: var(--color-white);
        box-shadow: var(--box-shadow-menu);
        border: 1px solid var(--color-grey);
        border-top: none;

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
</style>
