<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    posts: Array,
    header_type: String,
    link: String,
  });


  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
        spaceBetween: 16,
        slidesOffsetBefore: 10,
      },
      576: {
        slidesPerView: 2.5,
        spaceBetween: 16,
        slidesOffsetBefore: 10,
      },
      991: {
        slidesPerView: 4,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
      },
    },
  });
</script>
<template>
  <SfSection :title="header" :headerType="header_type" :link="link || route('web.blog.post.index')">
    <SfArticlesCarousel :posts :parameters />
  </SfSection>
</template>
