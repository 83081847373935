<script setup lang="ts">
  import { $patch, route } from '@plenny/visitor';
  import { trans } from '@plenny/translator';
  import { useBreakpoints } from '$storefront';
  import type { PropType } from 'vue';
  import type { Breadcrumb, Country } from '$types';

  type AccountCustomer = {
    company: string | null;
    country_code: string;
    created_at: string;
    email: string;
    email_verified_at: string;
    id: number;
    name: string;
    phone: string;
    type: string;
    updated_at: string;
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    countries: { type: Array as PropType<Country[]>, required: true },
    customer: { type: Object as PropType<AccountCustomer>, required: true },
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
  });

  const breakpoints = useBreakpoints();

  const accountTypes = [
    { label: trans('Osobiste'), value: 'personal' },
    { label: trans('Firmowe'), value: 'company' },
  ];

  function actionUpdate(data: AccountCustomer) {
    return $patch(route('web.account.update'), data);
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Moje Dane') }}
  </AccountMobileNavigation>
  <VisitorForm :initial="customer" :submit="actionUpdate" v-slot="{ data, submitting }" class="account-edit">
    <SfTile large class="account-edit__content">
      <template #title v-if="breakpoints.lg">
        <h1 class="account-heading account-edit__heading">{{ $t('Moje Dane') }}</h1>
      </template>
      <template #default>
        <div class="account-edit__data">
          <div class="account-edit__column">
            <h2 class="account-edit__column-heading">{{ $t('Zmiana danych') }}</h2>
            <SfFormRadio :label="$t('Zmień typ konta na')" name="type" :options="accountTypes" required />
            <SfFormSelect :label="$t('Kraj')" name="country_code" :options="countries" required />
            <SfFormInput v-if="data.type === 'company'" :label="$t('Nazwa firmy')" name="company" required />
            <SfFormInput v-if="data.type === 'personal'" :label="$t('Imię i nazwisko')" name="name" required />
            <SfFormInput :label="$t('E-mail')" name="email" required />
            <SfFormInput :label="$t('Telefon')" name="phone" />
          </div>
          <div class="account-edit__column">
            <h2 class="account-edit__column-heading">{{ $t('Zmiana hasła') }}</h2>
            <SfFormInput :label="$t('Aktualne hasło')" type="password" name="password" />
            <SfFormInput :label="$t('Nowe hasło')" type="password" name="new_password" />
            <SfFormInput :label="$t('Potwierdź hasło')" type="password" name="new_password_confirmation" />
          </div>
        </div>
      </template>
      <template #footer>
        <SfButton type="submit" primary :loading="submitting" class="account-edit__submit">
          {{ $t('Zapisz zmiany') }}
        </SfButton>
      </template>
    </SfTile>
  </VisitorForm>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-edit {
    &__column-heading,
    &__newsletter-heading {
      font-size: 1.125rem;
    }

    &__data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
    }

    &__column {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }

    &__newsletter {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  @include media.query(xl) {

    .account-edit {
      &__column-heading,
      &__newsletter-heading {
        font-size: 1rem;
      }

      &__data {
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__column {
        row-gap: 1.25rem;
      }

      &__newsletter {
        margin: 1rem 0;
        gap: 0.5rem;
      }

      &__submit {
        width: 100%;
      }
    }
  }
</style>
