<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [String, Number], required: false },
    modelValue: { type: [String, Number], required: false },
    defaultValue: { type: [String, Number], required: false, default: null },
    placeholder: { type: String, required: false, default: '' },
  });

  const { model, error } = useVisitorControl(props, emit);
</script>
<template>
  <SfControlWrapper floating v-bind="{ id, name, label, required, error }">
    <input class="control" v-bind="{ name, required, ...$attrs }" v-model="model" :placeholder="placeholder || label" :id="id || name" />
  </SfControlWrapper>
</template>
