<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: Number, required: false },
    modelValue: { type: Number, required: false },
    defaultValue: { type: Number, required: false, default: 0 },
  });

  const { model, error } = useVisitorControl(props, emit);
</script>

<template>
  <SfControlWrapper v-bind="{ id, name, label, required, error }" floating>
    <div class="stars__wrapper control">
      <input type="radio" :id="`${name}-0`" v-bind="{ name }" :value="0" v-model="model" />
      <label :for="`${name}-1`">
        <SfIconStar class="star" />
      </label>
      <input type="radio" :id="`${name}-1`" v-bind="{ name }" :value="1" v-model="model" />
      <label :for="`${name}-2`">
        <SfIconStar class="star" />
      </label>
      <input type="radio" :id="`${name}-2`" v-bind="{ name }" :value="2" v-model="model" />
      <label :for="`${name}-3`">
        <SfIconStar class="star" />
      </label>
      <input type="radio" :id="`${name}-3`" v-bind="{ name }" :value="3" v-model="model" />
      <label :for="`${name}-4`">
        <SfIconStar class="star" />
      </label>
      <input type="radio" :id="`${name}-4`" v-bind="{ name }" :value="4" v-model="model" />
      <label :for="`${name}-5`">
        <SfIconStar class="star" />
      </label>
      <input type="radio" :id="`${name}-5`" v-bind="{ name }" :value="5" v-model="model" />
    </div>
  </SfControlWrapper>
</template>

<style lang="scss">
  .stars__wrapper {
    display: flex;
    align-items: center;
    padding: var(--sf-form-stars-padding-y) var(--sf-form-stars-padding-x);
    gap: calc(var(--sf-form-stars-size) / 8);
    font-size: var(--sf-form-stars-size);

    input {
      height: 0;
      width: 0;
      opacity: 0;
      display: contents;

      &:checked {
        & ~ label {
          color: var(--sf-stars-color-star, rgb(220, 220, 220));
        }
      }
    }

    label {
      color: var(--sf-stars-color-star-filled, rgb(240, 220, 0));
      cursor: pointer;
    }
  }
</style>
