<script lang="ts" setup>
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [Number, Boolean], required: false },
    modelValue: { type: [Number, Boolean], required: false },
    defaultValue: { type: [Number, Boolean], required: false, default: false },
    reverse: { type: Boolean, required: false, default: false },
  });

  const switchClasses = {
    'switch__wrapper--reverse': props.reverse,
  };

  const { model, error } = useVisitorControl(props, emit);
</script>

<template>
  <SfControlWrapper v-bind="{ id, name, required, error }" class="control__switch">
    <label :class="switchClasses" class="switch__wrapper" :for="id || name">
      <input v-model="model" class="switch__input" type="checkbox" v-bind="{ name, ...$attrs }" :id="id || name" />
      <span class="control switch__button" />
      <span v-if="label || $slots.default" class="switch__label">
        <slot>{{ label }}</slot>
      </span>
    </label>
  </SfControlWrapper>
</template>

<style lang="scss">

  .control__switch .control__error {
    padding-left: calc((var(--sf-switch-line-height) * 2) + calc(var(--sf-switch-line-height) / 2));
  }

  .switch__wrapper {
    display: flex;
    margin: var(--sf-switch-margin-y) var(--sf-switch-margin-x);
    gap: calc(var(--sf-switch-line-height) / 2);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--reverse {
      flex-direction: row-reverse;
    }

    .switch__button {
      position: relative;
      display: inline-block;
      flex-shrink: 0;
      height: var(--sf-switch-line-height);
      width: calc(var(--sf-switch-line-height) * 2);
      border-radius: calc(var(--sf-switch-line-height) / 2);
      padding: 0;
      border: none;
      background-color: var(--sf-switch-background-color);

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        height: calc(var(--sf-switch-line-height) - 4px);
        width: calc(var(--sf-switch-line-height) - 4px);
        background-color: var(--sf-switch-btn-background-color);
        border-radius: calc(var(--sf-switch-line-height) / 2);
        transition-property: left, transform, background-color;
        transition-duration: var(--sf-input-transition-duration);
        transition-timing-function: var(--sf-input-transition-timing-func);
      }
    }

    .switch__input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:focus-visible:not(:disabled) ~ .switch__button {
        outline: 2px solid var(--sf-input-border-focus);
        outline-offset: 2px;
      }

      &:active:not(:disabled) ~ .switch__button {
        background-color: var(--sf-switch-background-color-active);
      }

      &:disabled {
        & ~ .switch__button {
          pointer-events: none;
          opacity: 0.7;
        }

        & ~ .switch__label {
          pointer-events: none;
          opacity: 0.7;
        }
      }

      &:checked ~ .switch__button {
        background-color: var(--sf-switch-background-color-checked);

        &:after {
          left: calc(100% - 2px);
          transform: translateX(-100%);
          background-color: var(--sf-switch-btn-background-color-checked);
        }
      }
    }

    .switch__label {
      display: inline-block;
      text-align: left;
      font-size: var(--sf-switch-font-size);
      line-height: var(--sf-switch-line-height);
      font-weight: var(--sf-switch-label-weight);
      text-transform: var(--sf-switch-label-text-transform);
      color: var(--sf-switch-label-color);
    }
  }
</style>
