<script lang="ts" setup>
  import { onMounted, onUnmounted, computed, watch, ref } from 'vue';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const props = defineProps({
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
  });

  const htmlModalElement = ref();
  const visible = ref(false);

  const wrapperClasses = computed(() => {
    return {
      'modal__wrapper--small': props.small,
      'modal__wrapper--large': props.large,
    };
  });

  function show() {
    htmlModalElement.value.showModal();
    visible.value = true;
  }

  // Dialog element is closed using transition's "after leave" event.
  // Otherwise, leaving transition wouldn't work.
  function hide() {
    visible.value && (visible.value = false);
  }

  function close() {
    htmlModalElement.value.close();
    open.value = false;
  }

  function onPopstate() {
    hide();
  }

  onMounted(() => {
    window.addEventListener('popstate', onPopstate);
    open.value && show();
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', onPopstate);
  });

  // We need to watch for open model change.
  // Otherwise, the modal won't hide properly when model is changed from parent.
  watch(open, (value) => {
    if (value) {
      show();
    } else {
      hide();
    }
  });
</script>
<template>
  <dialog ref="htmlModalElement" class="modal" v-bind="$attrs" @cancel.prevent="open = false" @click.stop="void 0">
    <transition name="modal-fade-up" @after-leave="close">
      <div v-if="visible" class="modal__container">
        <div :class="{ ...wrapperClasses }" class="modal__wrapper">
          <slot />
        </div>
      </div>
    </transition>
  </dialog>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-modal-backdrop-background: var(--backdrop-default);
    --sf-modal-background: rgb(255 255 255);
    --sf-modal-shadow: none;

    --sf-modal-gap-x: 2rem;
    --sf-modal-gap-y: 2rem;
    --sf-modal-padding-x: 2rem;
    --sf-modal-padding-y: 2rem;
    --sf-modal-container-padding-x: 2rem;
    --sf-modal-container-padding-y: 2rem;
    --sf-modal-max-width: 800px;
    --sf-modal-border-radius: var(--border-radius);

    --sf-modal-gap-x-sm: 1rem;
    --sf-modal-gap-y-sm: 1rem;
    --sf-modal-padding-x-sm: 2rem;
    --sf-modal-padding-y-sm: 2rem;
    --sf-modal-container-padding-x-sm: 2rem;
    --sf-modal-container-padding-y-sm: 2rem;
    --sf-modal-max-width-sm: 600px;
    --sf-modal-border-radius-sm: var(--border-radius);

    --sf-modal-gap-x-lg: 3rem;
    --sf-modal-gap-y-lg: 3rem;
    --sf-modal-padding-x-lg: 3rem;
    --sf-modal-padding-y-lg: 3rem;
    --sf-modal-container-padding-x-lg: 3rem;
    --sf-modal-container-padding-y-lg: 3rem;
    --sf-modal-max-width-lg: 1200px;
    --sf-modal-border-radius-lg: var(--border-radius);

    --sf-modal-fade-up-transition-property: opacity, transform;
    --sf-modal-fade-up-transition-timing-function: ease-out;
    --sf-modal-fade-up-transition-duration: 200ms;

    --sf-modal-fade-up-transform: scale(0.75);
    --sf-modal-fade-up-opacity: 0;
  }

  .modal {
    background: none;
    border: none;
    min-width: 100%;
    min-height: 100dvh;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    cursor: default;

    &::backdrop {
      background-color: var(--sf-modal-backdrop-background, --backdrop-default);
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 100dvh;
      padding: var(--sf-modal-container-padding-x) var(--sf-modal-container-padding-y);

      @include media.query(sm) {
        padding: 0;
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--sf-modal-gap-y) var(--sf-modal-gap-x);
      width: 100%;
      max-width: var(--sf-modal-max-width);
      min-height: 100dvh;
      padding: var(--sf-modal-padding-x) var(--sf-modal-padding-y);
      background-color: var(--sf-modal-background);
      box-shadow: var(--sf-modal-shadow);

      @media screen and (min-width: 576px) {
        border-radius: var(--sf-modal-border-radius);
        min-height: auto;
      }

      @include media.query(sm) {
        padding: calc(var(--sf-modal-padding-x) / 2) calc(var(--sf-modal-padding-y) / 2);
      }

      &--small {
        gap: var(--sf-modal-gap-x-sm) var(--sf-modal-gap-y-sm);
        padding: var(--sf-modal-padding-x-sm) var(--sf-modal-padding-y-sm);
        max-width: var(--sf-modal-max-width-sm);
        border-radius: var(--sf-modal-border-radius-sm);

        @include media.query(sm) {
          padding: calc(var(--sf-modal-padding-x-sm) / 2) calc(var(--sf-modal-padding-y-sm) / 2);
        }
      }

      &--large {
        gap: var(--sf-modal-gap-x-lg) var(--sf-modal-gap-y-lg);
        padding: var(--sf-modal-padding-x-lg) var(--sf-modal-padding-y-lg);
        max-width: var(--sf-modal-max-width-lg);
        border-radius: var(--sf-modal-border-radius-lg);

        @include media.query(sm) {
          gap: calc(var(--sf-modal-gap-x-lg) / 2) calc(var(--sf-modal-gap-y-lg) / 2);
          padding: calc(var(--sf-modal-padding-x-lg) / 2) calc(var(--sf-modal-padding-y-lg) / 2);
        }
      }

      @include media.query(sm) {
        border-radius: 0;
      }
    }
  }

  .modal-fade-up-enter-active,
  .modal-fade-up-leave-active {
    transition-property: var(--sf-modal-fade-up-transition-property);
    transition-timing-function: var(--sf-modal-fade-up-transition-timing-function);
    transition-duration: var(--sf-modal-fade-up-transition-duration);
  }

  .modal-fade-up-enter-from,
  .modal-fade-up-leave-to {
    transform: var(--sf-modal-fade-up-transform);
    opacity: var(--sf-modal-fade-up-opacity);
  }
</style>
