<script lang="ts" setup>
  import { ref, watch, type PropType } from 'vue';
  import { $get, url } from '@plenny/visitor';
  import type { Brand } from '$types';

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Array as PropType<Brand[]>, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
  });

  const form = ref();

  function onSubmit(data: any) {
    return $get(url(window.location.pathname, data));
  }

  function actionClear() {
    return $get(url(window.location.pathname));
  }

  watch(props, (value) => {
    form.value.context.set('brands', value.brands);
    form.value.context.set('price', value.price);
    form.value.context.set('attributes', value.attributes);
    form.value.context.set('search', value.search);
  });
</script>
<template>
  <div class="filters">
    <VisitorForm ref="form" :initial="{ brands, price, attributes, search }" @submit="onSubmit">

      <Collapsible>
        <template #header>
          <SfHeading type="span">
            {{ $t('Szukaj') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormInput :label="$t('Wpisz szukaną frazę')" name="search" type="text" @change="$refs.form.submit()" id="filters-search-input" />
          </div>
        </template>
      </Collapsible>

      <Collapsible v-if="categories.children.length > 0 || categories.category ||  categories.parent">
        <template #header>
          <SfHeading type="span">
            {{ $t('Kategoria') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <ShopProductsFilterCategories v-bind="{ categories }" />
          </div>
        </template>
      </Collapsible>

      <Collapsible v-if="options.brands.length > 0">
        <template #header>
          <SfHeading type="span">
            {{ $t('Marka') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormCheckbox :options="options.brands" name="brands" @change="$refs.form.submit()" />
          </div>
        </template>
      </Collapsible>

      <Collapsible>
        <template #header>
          <SfHeading type="span">
            {{ $t('Cena') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls filters__controls--price">
            <SfFormNumber :label="$t('Od')" min="0" name="price.min" @change="$refs.form.submit()" id="filter-price-min" />
            <SfFormNumber :min="price.min + 1" label="Do" name="price.max" @change="$refs.form.submit()" id="filter-price-max" />
          </div>
        </template>
      </Collapsible>

      <Collapsible v-for="option in options.attributes">
        <template #header>
          <SfHeading type="span">
            {{ option.label }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormCheckbox :name="`attributes.${option.value}`" :options="option.options"
              @change="$refs.form.submit()" />
          </div>
        </template>
      </Collapsible>

      <SfButton class="filters__clear" @click="actionClear">
        {{ $t('Wyczyść') }}
      </SfButton>
    </VisitorForm>
  </div>
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --sf-products-filter-gap: 1.5rem;
    --sf-products-filter-item-gap: 0.25rem;
    --sf-products-filter-controls-price-gap: 0.5rem;
    --sf-products-filter-clear-btn-width: 100%;
    --sf-products-filter-mobile-clear-btn-margin-top: 0;
    --sf-collapsible-input-background: var(--sf-input-background);

    @include media.query(lg) {
      --sf-products-filter-gap: 0;
      --sf-products-filter-item-gap: 0;
      --sf-products-filter-controls-price-gap: 1rem;
      --sf-products-filter-mobile-clear-btn-margin-top: 2rem;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sf-products-filter-gap);
    --sf-collapsible-gap: var(--sf-products-filter-item-gap);
    --sf-input-background: var(--sf-collapsible-input-background);

    &__controls--price {
      display: flex;
      gap: var(--sf-products-filter-controls-price-gap);
    }

    &__clear {
      width: var(--sf-products-filter-clear-btn-width);
      margin-top: var(--sf-products-filter-mobile-clear-btn-margin-top);
    }
  }
</style>
