<script lang="ts" setup>
  import { useBreakpoints } from '$storefront';
  import type { PropType } from 'vue';
  import type { Breadcrumb, Section, Module } from '$types';

  const breakpoints = useBreakpoints();

  defineProps({
    attributes: Array,
    variants: Array,
    matrix: Object,
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
    sections: { type: Array as PropType<Section[]>, required: true },
    module: { type: Object as PropType<Module>, required: true },
  });
</script>
<template>
  <main class="container comparator-site">
    <SfBreadcrumbs :breadcrumbs />
    <SectionRenderer :sections placement="before" />
    <div class="comparator-site__wrapper">
      <div class="comparator-site__header">
        <SfHeading larger type="h1">{{ module.title }}</SfHeading>
        <div v-if="module.description_short" class="comparator-site__description" v-html="module.description_short" />
      </div>
      <SfComparator :stickyDistance="breakpoints.lg ? 90 : 50" v-bind="{ attributes, variants, matrix }" />
    </div>
    <SectionRenderer :sections="sections" placement="after" />
  </main>
</template>
<style lang="scss" scoped>
  @use "$assets/mixins/media";

  .comparator-site {
    margin-bottom: 60px;

    &__wrapper {
      background-color: var(--color-white);
      padding: 2rem;
    }

    &__header {
      text-align: center;
      margin-bottom: 2.5rem;
    }

    &__description {
      margin: 1rem 0;
    }

    @include media.query(lg) {
      &__wrapper {
        padding: 1.5rem 0;
      }

      &__header {
        margin-bottom: 1rem;
      }
    }
  }
</style>
