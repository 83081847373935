<script lang="ts" setup>
  import { ref, computed, type PropType } from 'vue';
  import { MediaPicture, type Photo } from '@plenny/picture';

  const props = defineProps({
    photos: { type: Array as PropType<Photo[]>, required: true },
  });

  const open = ref(false);
  const activePhotoIndex = ref(0);
  const manyPhotos = computed(() => props.photos.length > 1);
  const touchstartX = ref(0);
  const touchstartY = ref(0);
  const touchendX = ref(0);
  const touchendY = ref(0);
  // To make touch events less sensitive
  const touchTolerance = 100;

  const htmlLightboxElement = ref();

  function openAction(index: number) {
    open.value = true;
    htmlLightboxElement.value.showModal();
    activePhotoIndex.value = index;
  }

  function closeAction() {
    open.value = false;
    activePhotoIndex.value = 0;
    htmlLightboxElement.value.close();
  }

  function nextPhoto() {
    if (props.photos.length - 1 === activePhotoIndex.value) {
      activePhotoIndex.value = 0;
    } else {
      activePhotoIndex.value = activePhotoIndex.value + 1;
    }
  }

  function prevPhoto() {
    if (activePhotoIndex.value === 0) {
      activePhotoIndex.value = props.photos.length - 1;
    } else {
      activePhotoIndex.value = activePhotoIndex.value - 1;
    }
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      closeAction();
    } else if (event.key === 'ArrowLeft') {
      prevPhoto();
    } else if (event.key === 'ArrowRight') {
      nextPhoto();
    }
  }

  function onTouchStart(event: TouchEvent) {
    touchstartX.value = event.touches[0].clientX;
    touchstartY.value = event.touches[0].clientY;
  }

  function onTouchEnd(event: TouchEvent) {
    touchendX.value = event.changedTouches[0].clientX;
    touchendY.value = event.changedTouches[0].clientY;

    if (touchstartY.value > touchendY.value + touchTolerance) {
      closeAction();
    }
    if (touchendX.value + touchTolerance < touchstartX.value) {
      nextPhoto();
    }
    if (touchendX.value > touchstartX.value + touchTolerance) {
      prevPhoto();
    }
  }

  defineExpose({ open: openAction });
</script>
<template>
  <dialog ref="htmlLightboxElement" :class="{'lightbox--open': open}" class="lightbox" v-bind="$attrs" @keydown="onKeyDown">
    <div class="lightbox__content">
      <div class="lightbox__top">
        <div class="lightbox__buttons">
          <SfButton square @click="closeAction">
            <SfIconClose />
          </SfButton>
        </div>
        <div class="lightbox__main-photo" @touchend="onTouchEnd" @touchstart="onTouchStart">
          <MediaPicture :photo="photos[activePhotoIndex]" aria-live="polite" />
        </div>
      </div>
      <template v-if="manyPhotos">
        <div class="lightbox__arrow-container" @click="prevPhoto">
          <SfButton class="lightbox__arrow" rounded square>
            <SfIconChevronLeft class="lightbox__icon" />
          </SfButton>
        </div>
        <div class="lightbox__arrow-container lightbox__arrow-container--right" @click="nextPhoto">
          <SfButton class="lightbox__arrow" rounded square>
            <SfIconChevronLeft class="lightbox__icon" />
          </SfButton>
        </div>
      </template>
      <ul v-if="manyPhotos" class="lightbox__thumbnails">
        <li v-for="(photo, index) in photos"
          :aria-current="index === activePhotoIndex"
          :aria-label="`Miniatura ${index + 1}`"
          :class="{'lightbox__thumbnail--active': index === activePhotoIndex }"
          class="lightbox__thumbnail"
        >
          <button class="lightbox__button">
            <MediaPicture :photo="photo" sizes="160x160 2x, 80x80" @click="activePhotoIndex = index" />
          </button>
        </li>
      </ul>
    </div>
  </dialog>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-lightbox-border-color: var(--secondary-border);
    --sf-lightbox-border-color-active: var(--primary-background);
    --sf-lightbox-border-color-hover: var(--secondary-border-hover);
    --sf-lightbox-thumbnail-border-radius: var(--border-radius);
  }

  .lightbox {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.2s;

    &--open {
      opacity: 1;
    }

    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &__top {
      height: calc(100dvh - 120px);
    }

    &__buttons {
      position: absolute;
      top: 2%;
      right: 2%;
      display: flex;
      gap: 0.375rem;
      z-index: 1;
    }

    &__main-photo {
      height: 100%;

      img {
        height: 100%;
      }
    }

    &__arrow-container {
      position: absolute;
      width: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      left: 2%;
      top: 50%;
      transform: translateY(-50%);

      &--right {
        left: unset;
        right: 2%;

        .lightbox__arrow {
          transform: rotate(180deg);
        }
      }

      @include media.query(sm) {
        width: 2.5rem;
      }
    }

    &__icon {
      font-size: 1.75rem;
      @include media.query(sm) {
        font-size: 0.875rem;
      }
    }

    &__thumbnails {
      display: flex;
      gap: 0.625rem;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: center;
      list-style: none;
      min-height: 90px;
      padding: 0 1rem;

      @include media.query(lg) {
        justify-content: start;
      }
    }

    &__thumbnail {
      position: relative;
      min-width: 5rem;
      display: flex;
      padding: 2px;

      img {
        user-select: none;
        object-position: center;
        object-fit: cover;
        border: 1px solid var(--sf-lightbox-border-color);
        border-radius: var(--sf-lightbox-thumbnail-border-radius);
        transition-property: border, box-shadow;
        transition-timing-function: var(--sf-input-transition-timing-func);
        transition-duration: var(--sf-input-transition-duration);

        @media(hover: hover) {
          &:hover {
            border-color: var(--sf-lightbox-border-color-hover);
          }
        }
      }

      &--active .lightbox__button img {
        border-color: var(--sf-lightbox-border-color-active);
        box-shadow: var(--primary-box-shadow-100);
        user-select: none;
      }
    }

    &__button {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border: none;
    }
  }
</style>
